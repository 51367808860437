import { get, set, createStore, entries } from 'idb-keyval';

export const i2goSettingsID = 'i2Go2022';

const i2goDbSettings = createStore(i2goSettingsID, 'store');

const getRepo = (key) => {
  return new Promise((resolve) => {
    get(key, i2goDbSettings).then((value) => {
      if (!value) {
        value = null;
        setRepo(key, value).then(() => {
          resolve(value);
        });
      } else {
        resolve(value);
      }
    });
  });
};

export const getDirty = () => {
  return new Promise((resolve) => {
    readRepo('dirty').then((isDirty) => {
      return resolve(isDirty);
    });
  });
};

export const readPackedRepo = () => {
  return new Promise((resolve) => {
    let r = {};
    entries(i2goDbSettings).then((asArray) => {
      for (let keyVal of asArray) {
        r = Object.assign({}, r, {
          [keyVal[0]]: keyVal[1],
        });
      }
      return resolve(r);
    });
  });
};

/**
 * @param def The idea here is to create the `(key, def)` pair in the store
 * if it doesn't exist yet. The function will return `def` as if it existed
 * and then create it so it actually exist. This saves time to the main thread
 * programmer who won't need to check and create.
 */
export const readRepo = (key, def = null) => {
  return new Promise((resolve) => {
    getRepo(key).then((repo) => {
      if (!repo && def !== null) {
        writeRepo(key, def).then(() => {
          return resolve(def);
        });
      }
      return resolve(repo);
    });
  });
};

export const setClean = () => {
  return writeRepo('dirty', false);
};

export const setDirty = () => {
  return writeRepo('dirty', true);
};

const setRepo = (key, val) => {
  return set(key, val, i2goDbSettings);
};

export const writeRepo = (key, val) => {
  return new Promise((resolve) => {
    getRepo(key).then(() => {
      setRepo(key, val).then(() => {
        return resolve();
      });
    });
  });
};
