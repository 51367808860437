import { getAppliedLanguage } from '../../locale';
import { useState, useEffect } from 'react';

export const CATEGORIES = [
  {
    value: 'all',
    label: {
      en: 'All categories',
      fr: 'Toutes les categories',
      es: 'Todas las categorías',
    },
  },
  {
    value: 'birthday',
    label: {
      en: 'Birthday',
      fr: 'Anniversaire',
      es: 'Cumpleaños',
    },
  },
  {
    value: 'babyshower',
    label: {
      en: 'Baby shower',
      fr: 'Baby shower',
      es: 'Baby shower',
    },
  },
  {
    value: 'barbecue',
    label: {
      en: 'Barbecue',
      fr: 'Barbecue',
      es: 'Parrillada',
    },
  },
  {
    value: 'christmas',
    label: {
      en: 'Christmas',
      fr: 'Noël',
      es: 'Navidad',
    },
  },
  {
    value: 'halloween',
    label: {
      en: 'Halloween',
      fr: 'Halloween',
      es: 'Halloween',
    },
  },
  {
    value: 'playtogether',
    label: {
      en: 'Play together',
      fr: 'Jouer ensemble',
      es: 'Juntarse a jugar',
    },
  },
  {
    value: 'gettogether',
    label: {
      en: 'Get together',
      fr: 'Rencontre',
      es: 'Reunión',
    },
  },
];

export const useGetLocalizedCategoriesList = (lang) => {
  if (!lang) {
    lang = getAppliedLanguage();
  }
  const [data, setData] = useState(null);
  const [status] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      const localizedList = CATEGORIES.map((item) => {
        return {
          value: item.value,
          label: item.label[lang],
        };
      });
      setLoading(false);
      setData(localizedList);
    }

    run();
  }, [lang]);

  return [data, loading, status];
};
