import './WishCategoryCard.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../components/Card/Card';
import IconButton from '../../../../components/Icon/Icon';
import { useGetWishItemsByCategoryUuid } from '../../../../services/data/hooks/wish-items';

function WishCategoryCard(props) {
  // const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [wishItems] = useGetWishItemsByCategoryUuid(props.data.uuid);
  const [count, setCount] = useState(0);
  const [firstItem, setFirstItem] = useState(null);

  useEffect(() => {
    if (wishItems) {
      setCount(wishItems.length);
      setFirstItem(wishItems.length ? wishItems[0] : null);
    }
  }, [wishItems]);

  function onCardEdit() {
    if (props.onEdit) {
      props.onEdit(props.data);
    }
  }

  function renderCardHtml() {
    if (!props.data) {
      return null;
    } else {
      const imageUrl = firstItem ? firstItem.image_url : '';
      return (
        <Card>
          <div className="wishCategoryInfo">
            {firstItem && (
              <div
                className="calculatedImage"
                style={{ backgroundImage: `url(${imageUrl})` }}
              >
                <span className="countBadge">{count}</span>
              </div>
            )}
            <div className="name">{props.data.name}</div>
          </div>
          <div className="wishCategoryButtons">
            <IconButton
              clickable
              name="mode-edit"
              onClick={onCardEdit}
            ></IconButton>
          </div>
        </Card>
      );
    }
  }

  return <div className="WishCategoryCard">{renderCardHtml()}</div>;
}

WishCategoryCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WishCategoryCard;
