import { apiPost } from './ajax';
import { makeApiUrl } from './';
import { readPackedRepo } from '../storage';
import { setGuests, setGuestActions } from '../storage/guests';
import { setAppVersion } from '../storage/app-version';

const parseIncomingAppVersion = (version) => {
  return setAppVersion(version);
};

const parseIncomingGuestUpdates = (updates) => {
  return setGuests(updates);
};

const parseIncomingState = (state) => {
  return new Promise((resolve) => {
    const promises = [];
    if (state.updates) {
      if (state.updates.guests) {
        promises.push(parseIncomingGuestUpdates(state.updates.guests));
      }
      if (state.updates.guestActions) {
        promises.push(
          parseIncomingGuestActionsUpdates(state.updates.guestActions)
        );
      }
      if (state.updates.appVersion) {
        promises.push(parseIncomingAppVersion(state.updates.appVersion));
      }
    }
    Promise.all(promises).then(() => {
      return resolve();
    });
  });
};

const parseIncomingGuestActionsUpdates = (updates) => {
  return setGuestActions(updates);
};

export const syncState = () => {
  return new Promise((resolve) => {
    readPackedRepo().then((repo) => {
      const payload = {
        deviceId: repo.deviceId,
        profiles: repo.profiles,
        settings: repo.settings,
      };
      apiPost({
        url: makeApiUrl('state'),
        payload,
      }).then((response) => {
        if (response) {
          parseIncomingState(response.json).then(() => {
            return resolve(true);
          });
        }
      });
    });
  });
};
