import { useState } from 'react';
import Locale from '../../../../services/locale';
import DICTIONARY from './dictionary.json';
import InputText from '../../../../components/Form/InputText';
import OutlineButton from '../../../../components/Button/OutlineButton';
import HelpModal from '../../../../components/HelpModal/HelpModal';
import Icon from '../../../../components/Icon/Icon';
import { fetchProductInfo } from '../../../../services/data/api/product-info';
import { toast } from 'react-toastify';

function WishItemSearchInput({ field, form, ...props }) {
  const SAY = new Locale([DICTIONARY]);
  const {
    onBeforeFetchingInfo,
    onProductInfoReceived,
    onInputChange,
    autoComplete,
    type,
    ...restProps
  } = props;
  const [loading, setLoading] = useState(false);
  const { onChange, ...restField } = field;

  function emptyValue() {
    return !field || !field.value || field.value.length < 1;
  }

  function customOnChange(evt) {
    if (onInputChange) {
      onInputChange(evt);
    }
    onChange(evt);
  }

  function onFetchInfo() {
    setLoading(true);
    const url = field.value;
    if (onBeforeFetchingInfo) {
      onBeforeFetchingInfo(url);
    }
    fetchProductInfo(url)
      .then((result) => {
        if (onProductInfoReceived) {
          onProductInfoReceived(result);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(SAY.errors[error.error], {
          autoClose: false,
          closeOnClick: true,
        });
        setLoading(false);
      });
  }

  function resetUrl() {
    if (props.onReset) {
      props.onReset();
    }
  }

  function renderButton() {
    if (loading) {
      return <Icon name="settings" spin />;
    } else {
      return (
        <OutlineButton onClick={onFetchInfo} disabled={emptyValue()}>
          {SAY.fetchInfo}
        </OutlineButton>
      );
    }
  }

  return (
    <div className="WishItemSearchInput">
      <InputText
        autoComplete="off"
        onChange={customOnChange}
        onTrailingIconButtonClick={resetUrl}
        trailingIconButton="close"
        type="text"
        {...restField}
        {...restProps}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {renderButton()}
        <HelpModal buttonLabel="OK">{SAY.explainFetchingProductInfo}</HelpModal>
      </div>
    </div>
  );
}

export default WishItemSearchInput;
