import { Remarkable } from 'remarkable';

export default function mdToHtml(raw) {
  const md = new Remarkable();
  md.set({
    html: true,
    breaks: true,
  });
  return md.render(raw);
}
