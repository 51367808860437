import { readRepo, writeRepo } from '../storage';
import { setActiveLanguage } from '../local-storage';
import { setDirty } from '../storage';

export const EMPTY_PROFILE = {
  uuid: '',
  name: '',
  language: 'en',
  avatar: 'default',
  is_deleted: false,
};

export const addProfile = (data) => {
  return new Promise((resolve, reject) => {
    readAllProfiles().then((profiles) => {
      profiles.push(data);
      writeRepo('profiles', profiles)
        .then(() => {
          getIdentifiedProfile(data.uuid)
            .then((profile) => {
              if (profiles.length === 1) {
                setActiveProfile(profile);
              }
              return resolve(profile);
            })
            .catch((error) => {
              return reject(error);
            });
        })
        .catch((error) => {
          return reject(error);
        });
    });
  });
};

export const deleteProfileByUuid = (uuid) => {
  return new Promise((resolve) => {
    getAllProfiles().then((profiles) => {
      const i = profiles.findIndex((item) => {
        return item.uuid === uuid;
      });
      if (i < 0) {
        return resolve(profiles);
      }
      profiles.splice(i, 1);
      writeAllProfiles(profiles).then(() => {
        readAllProfiles().then((modifiedProfiles) => {
          return resolve(modifiedProfiles);
        });
      });
    });
  });
};

export const findProfileByUuid = (profiles, uuid) => {
  if (!profiles || !uuid) {
    return null;
  }
  if (uuid === 'new') {
    return EMPTY_PROFILE;
  }
  const found = profiles.find((item) => {
    return item.uuid === uuid;
  });
  return found || null;
};

export const getActiveProfile = () => {
  return new Promise((resolve) => {
    readActiveProfile().then((uuid) => {
      if (!uuid) {
        return resolve(null);
      }
      getIdentifiedProfile(uuid).then((profile) => {
        return resolve(profile || null);
      });
    });
  });
};

export const getAllProfiles = () => {
  return new Promise((resolve) => {
    readAllProfiles().then((profiles) => {
      return resolve(profiles);
    });
  });
};

export const getIdentifiedProfile = (uuid) => {
  return new Promise((resolve, reject) => {
    readAllProfiles().then((profiles) => {
      const profile = findProfileByUuid(profiles, uuid);
      if (!profile) {
        return reject('Profile not found');
      }
      return resolve(profile);
    });
  });
};

export const readActiveProfile = () => {
  return readRepo('activeProfile');
};

export const readAllProfiles = () => {
  return readRepo('profiles', []);
};

export const setActiveProfile = (profile) => {
  return new Promise((resolve) => {
    setActiveLanguage(profile.language);
    return resolve(writeActiveProfile(profile.uuid));
  });
};

export const updateProfile = (uuid, newData) => {
  return new Promise((resolve, reject) => {
    getIdentifiedProfile(uuid).then((profile) => {
      const modifiedProfile = Object.assign({}, profile, newData);
      readAllProfiles().then((profiles) => {
        const i = profiles.findIndex((item) => {
          return item.uuid === uuid;
        });
        if (i < 0) {
          return reject(`Profile not found ${uuid}`);
        } else {
          profiles[i] = modifiedProfile;
          writeAllProfiles(profiles).then(() => {
            getIdentifiedProfile(profile.uuid).then((updatedProfile) => {
              resolve(updatedProfile);
            });
          });
        }
      });
    });
  });
};

const writeActiveProfile = (value) => {
  return new Promise((resolve) => {
    writeRepo('activeProfile', value).then(() => {
      setDirty().then(() => {
        return resolve();
      });
    });
  });
};

const writeAllProfiles = (value) => {
  return new Promise((resolve) => {
    writeRepo('profiles', value).then(() => {
      setDirty().then(() => {
        return resolve(value);
      });
    });
  });
};
