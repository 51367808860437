import './WishCategoryEditorPage.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Locale from '../../services/locale';
import DICTIONARY from './dictionary.json';
import Toolbar from '../../components/Toolbar/Toolbar';
import ROUTES from '../../services/routes';
import WishCategoryForm from './components/WishCategoryForm/WishCategoryForm';
import Loading from '../../components/Loading/Loading';
import generateUuid from '../../services/data/helpers/uuid';
import {
  addWishCategory,
  deleteWishCategoryByUuid,
  updateWishCategory,
} from '../../services/data/helpers/wish-categories';
import { useGetWishCategoryByUuid } from '../../services/data/hooks/wish-categories';
import { useGetWishItemsByCategoryUuid } from '../../services/data/hooks/wish-items';
import { toast } from 'react-toastify';
// import ToolbarButtonBack from '../../components/Toolbar/components/ToolbarButtonBack/ToolbarButtonBack';

function WishCategoryEditorPage() {
  const SAY = new Locale([DICTIONARY]);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [mode] = useState(!uuid || uuid === 'new' ? 'create' : 'edit');
  const [theWishCategory, loadingWishCategory] = useGetWishCategoryByUuid(uuid);
  const [data, setData] = useState();
  const [wishItems] = useGetWishItemsByCategoryUuid(uuid);

  useEffect(() => {
    if (!loadingWishCategory && theWishCategory) {
      setData(theWishCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theWishCategory]);

  function navigateBack() {
    navigate(ROUTES.wishCategories);
  }

  function deleteWishCategory(theCategory) {
    deleteWishCategoryByUuid(theCategory.uuid)
      .then(() => {
        toast.success(SAY.formatString(SAY.categoryDeleted, theCategory.name), {
          autoClose: 1000,
        });
        navigateBack();
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  function onFormCancel() {
    navigateBack();
  }

  function onSubmit(values) {
    if (mode === 'create') {
      values.uuid = generateUuid();
      addWishCategory(values)
        .then(() => {
          navigateBack();
        })
        .catch((error) => console.error('ERROR', error));
    } else {
      updateWishCategory(uuid, values)
        .then(() => {
          navigateBack();
        })
        .catch((error) => console.error('ERROR', error));
    }
  }

  function renderToolbar() {
    const subTitle = mode === 'create' ? SAY.createNew : SAY.edit;
    return (
      <Toolbar
        goBack
        title={SAY.wishCategories}
        subTitle={subTitle}
        enableMenu
        withMenu
        withSidebar
      />
    );
  }

  function renderView() {
    if (!data) {
      return <Loading />;
    } else {
      return (
        <WishCategoryForm
          data={data}
          forEdit={mode === 'edit'}
          canDelete={!wishItems || wishItems.length === 0}
          onCancel={() => onFormCancel()}
          onDelete={(theCategory) => deleteWishCategory(theCategory)}
          onSubmit={onSubmit}
        />
      );
    }
  }

  return (
    <div className="pageWithMenuLayout">
      {renderToolbar()}
      <div className="WishCategoryEditorPage page withButtonsBar">
        {renderView()}
      </div>
    </div>
  );
}

export default WishCategoryEditorPage;
