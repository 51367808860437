import './InputText.scss';
import React, { useRef, useEffect } from 'react';
import { useField } from 'formik';
import Icon from '../Icon/Icon';
import TipText from '../OnBoard/TipText';

// https://formik.org/docs/api/useField
function InputText(props) {
  const {
    tipText,
    type,
    trailingIconButton,
    onTrailingIconButtonClick,
    focus,
    ...rest
  } = props;
  const inputType = type || 'text';
  const [field, meta] = useField(props);
  const inputElement = useRef(null);
  const disabledClass = props.disabled ? 'disabled' : '';
  const className = `inputContainer ${disabledClass} ${
    props.className || ''
  } type-${inputType}`;
  const notEmptyClass = meta.value !== '' ? 'notEmpty' : '';
  const inputClassName = `input ${notEmptyClass}`;

  useEffect(() => {
    if (focus) {
      inputElement.current.focus();
    }
  }, [focus]);

  const leadingIcon = () => {
    if (!props.leadingIcon) {
      return null;
    } else {
      return (
        <div className="leadingIcon">
          <Icon name={props.leadingIcon} />
        </div>
      );
    }
  };

  const trailingIcon = () => {
    if (!trailingIconButton || !field || !field.value || field.value.length < 1) {
      return null;
    } else {
      const onClick = onTrailingIconButtonClick
        ? onTrailingIconButtonClick
        : null;
      return (
        <div className="trailingIcon" onClick={onClick}>
          <Icon name={trailingIconButton} />
        </div>
      );
    }
  };

  const tipTextExplain = () => {
    if (!tipText) {
      return null;
    } else {
      return <TipText>{tipText}</TipText>;
    }
  };

  return (
    <div className="InputText">
      <div
        className={className}
        onClick={(_e) => {
          inputElement.current.focus();
        }}
      >
        {leadingIcon()}
        <input
          className={inputClassName}
          ref={inputElement}
          type={inputType}
          {...field}
          {...rest}
        />
        {trailingIcon()}
        {props.label && (<label>{props.label}</label>)}
      </div>
      {tipTextExplain()}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default InputText;
