import './InvitationCard.scss';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../services/dictionary/common-dictionary.json';
import Locale from '../../services/locale';
import { formattedDate, formattedTime } from '../../services/locale/date-time';
import Card from '../Card/Card';
import Button from '../Button/Button';
import TextButton from '../Button/TextButton';
import IconButton from '../Button/IconButton';
import LinkButton from '../Button/LinkButton';
import { invitationPreviewUrl } from '../../services/url';
import { useActiveProfile } from '../../services/data/hooks/profiles';
import { invitationAnonymousUrl } from '../../services/url';
import { toast } from 'react-toastify';
import ROUTES, { makePath } from '../../services/routes';
import { useGetGuestsForInvitation } from '../../services/data/hooks/guests';

function InvitationCard(props) {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [guests] = useGetGuestsForInvitation(props.data.uuid);
  const [activeProfile] = useActiveProfile();
  const invitationUrlRef = useRef(null);
  const navigate = useNavigate();

  function calculateEditButtonTheme() {
    return props.data.service === 'event' ? 'primary' : 'secondary';
  }

  function makeUrl() {
    const url = invitationAnonymousUrl(props.data.uuid);
    return url;
  }

  function onCopyUrlToClipboard() {
    invitationUrlRef.current.select();
    const url = invitationUrlRef.current.value;
    navigator.clipboard.writeText(url);
    toast.success(`OK, ${url}`, { autoClose: 1000 });
  }

  function onNavigateToGuestsList() {
    navigate(makePath(ROUTES.invitationGuests, { uuid: props.data.uuid }));
  }

  function onNavigateToOutbox() {
    navigate(makePath(ROUTES.invitationOutbox, { uuid: props.data.uuid }));
  }

  function onNavigateToPosts() {
    navigate(makePath(ROUTES.invitationPosts, { uuid: props.data.uuid }));
  }

  function onCardCheckout() {
    if (props.onCheckout) {
      props.onCheckout(props.data);
    }
  }

  function onCardEdit() {
    if (props.onEdit) {
      props.onEdit(props.data);
    }
  }

  function onShareUrl() {
    navigator.share({ url: makeUrl() });
  }

  function usingPremiumTheme() {
    return false;
  }

  function renderCheckoutButtonHtml() {
    if (usingPremiumTheme()) {
      return (
        <IconButton
          noBorder
          icon="shopping-cart"
          onClick={onCardCheckout}
          size="32"
          theme="outline success"
        ></IconButton>
      );
    }
  }

  function renderGuestsLinkButton() {
    if (!guests || !guests.length) {
      return null;
    } else {
      const label = guests.length > 1 ? SAY.guests : SAY.guest;
      return (
        <TextButton
          style={{ cursor: 'pointer', padding: '0px' }}
          onClick={onNavigateToGuestsList}
        >
          {guests.length} {label}
        </TextButton>
      );
    }
  }

  function renderThemeImageHtml() {
    if (!props.theme) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="100"
          height="100"
        >
          <path fill="#59f" d="M0 0h100v100H0z" />
          <path
            d="M0 0v2.594c6.897 6.857 17.946 9.508 26.895 4.789 1.788 12.108 11.429 21.306 23.13 21.306 11.644 0 22.794-9.213 23.58-21.158 8.86 4.366 19.626 1.68 26.395-5.053V0z"
            fill="#fc0"
          />
        </svg>
      );
    } else {
      return (
        <img
          alt={SAY.formatString(SAY.edit, props.data.title)}
          title={SAY.formatString(SAY.edit, props.data.title)}
          onClick={onCardEdit}
          src={props.theme.image_url}
        />
      );
    }
  }

  function renderShareButtonsHtml() {
    if (!props.data.published) {
      return (
        <span
          style={{
            fontWeight: 'bold',
            width: '100%',
            padding: '6px',
            textAlign: 'right',
          }}
        >
          <Button
            trailingIcon="mode-edit"
            onClick={onCardEdit}
            theme={calculateEditButtonTheme()}
          >
            {SAY.editToPublish}
          </Button>
        </span>
      );
    } else {
      return (
        <>
          <IconButton
            noBorder
            icon="quick-phrases"
            onClick={onNavigateToPosts}
            size="32"
            theme="outline primary"
          ></IconButton>
          <IconButton
            noBorder
            icon="share"
            onClick={onShareUrl}
            size="32"
            theme="outline primary"
          ></IconButton>
          <IconButton
            noBorder
            icon="content-copy"
            onClick={onCopyUrlToClipboard}
            size="32"
            theme="outline primary"
          ></IconButton>
          <IconButton
            icon="mode-edit"
            onClick={onCardEdit}
            size="32"
            theme={calculateEditButtonTheme()}
          ></IconButton>
        </>
      );
    }
  }

  function renderCardHtml() {
    if (!props.data || !activeProfile) {
      return null;
    } else {
      const cardImageClassName = `invitationImage ${props.data.service}`;
      const serviceClassName = `invitationService ${props.data.service}`;

      return (
        <Card>
          <div className={cardImageClassName}>
            <div className={serviceClassName}>
              {SAY.services[props.data.service]}
            </div>
            <div style={{ display: 'block', width: '100px', height: '100px' }}>
              {renderThemeImageHtml()}
            </div>
          </div>

          <div className="invitationTitle">{props.data.title}</div>
          <div className="invitationSpecs">
            {props.data.date && <p>{formattedDate(props.data.date)}</p>}
            {props.data.start_tm && props.data.end_tm && (
              <p>
                {formattedTime(props.data.start_tm)}
                &nbsp;..&nbsp;
                {formattedTime(props.data.end_tm)}
              </p>
            )}
            <div className="buttonPreview">
              <LinkButton
                theme="primary"
                trailingIcon="open-in-new"
                href={invitationPreviewUrl(activeProfile, props.data)}
                rel="noopener, noreferrer"
                target="_blank"
              >
                {SAY.preview}
              </LinkButton>
            </div>
            {renderGuestsLinkButton()}
            <input
              className="invitationUrl"
              ref={invitationUrlRef}
              style={{ display: 'none' }}
              type="hidden"
              value={makeUrl()}
            />
          </div>
          <div className="invitationButtons">
            {renderCheckoutButtonHtml()}
            <IconButton
              noBorder
              icon="person-add"
              onClick={onNavigateToOutbox}
              size="32"
              theme="outline primary"
            ></IconButton>
            {renderShareButtonsHtml()}
          </div>
        </Card>
      );
    }
  }

  return <div className="InvitationCard">{renderCardHtml()}</div>;
}

InvitationCard.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

export default InvitationCard;
