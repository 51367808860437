import DefaultAvatar from './DefaultAvatar';
import { avatarUrl } from '../../services/url';

function Avatar(props) {
  const dimensions = {
    width: props.width || '100px',
    height: props.height || '100px',
  };
  const newProps = Object.assign({}, dimensions, props);
  if (!props.name || props.name === 'default') {
    return <DefaultAvatar {...newProps} />;
  } else {
    const style = {
      width: '100%',
    };
    return <img style={style} src={avatarUrl(props.name)} alt={props.name} />;
  }
}

export default Avatar;
