import Toolbar from '../../components/Toolbar/Toolbar';
import { useFetchHomePage } from '../../services/data/hooks/home';

function HomePage() {
  const [homeContent, loadingHomeContent] = useFetchHomePage();

  function renderHomeContentHtml() {
    if (loadingHomeContent) {
      return null;
    } else {
      return <div dangerouslySetInnerHTML={{__html: homeContent}} />;
    }
  }

  return (
    <div className="pageWithMenuLayout">
      <Toolbar title="Invitogo" enableMenu withMenu withSidebar />
      <div className="HomePage">{renderHomeContentHtml()}</div>
    </div>
  );
}

export default HomePage;
