import PropTypes from 'prop-types';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Card from '../WishItemCard/WishItemCard';

function WishItemsList(props) {
  function onCardEdit(data) {
    if (props.onCardEdit) {
      props.onCardEdit(data);
    }
  }

  function renderListHtml() {
    return props.data.map((wishItem, i) => {
      return <Card data={wishItem} onEdit={onCardEdit} key={i} />;
    });
  }

  return (
    <ResponsiveMasonry
      className="WishItemsList"
      columnsCountBreakPoints={{
        280: 1,
        450: 2,
        920: 3,
        1150: 4,
        1500: 5,
      }}
    >
      <Masonry>{renderListHtml()}</Masonry>
    </ResponsiveMasonry>
  );
}

WishItemsList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default WishItemsList;
