import './InvitationFormThemeInput.scss';
import ThemeCard from '../../../../components/ThemeCard/ThemeCard';
import Button from '../../../../components/Button/Button';
import DICTIONARY from './dictionary.json';
import Locale from '../../../../services/locale';
import Loading from '../../../../components/Loading/Loading';

function InvitationFormThemeInput(props) {
  const SAY = new Locale([DICTIONARY]);

  function onSelectTheme() {
    if (props.onClick) {
      props.onClick();
    }
  }

  function renderHtml() {
    const buttonLabel = props.theme ? SAY.changeTheme : SAY.selectTheme;

    if (props.loading) {
      return (
        <div
          style={{
            height: '249px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading inline size="100" />
        </div>
      );
    } else {
      return (
        <>
          {props.theme && <ThemeCard row data={props.theme} viewOnly />}
          <div style={{marginTop: '16px'}}>
            <Button
              theme="secondary"
              trailingIcon="chevron-right"
              onClick={() => onSelectTheme()}
            >
              {buttonLabel}
            </Button>
          </div>
        </>
      );
    }
  }

  return <div className="InvitationFormThemeInput">{renderHtml()}</div>;
}

export default InvitationFormThemeInput;
