import './InputTextArea.scss';
import React, { useRef } from 'react';
import { useField } from 'formik';
import TipText from '../OnBoard/TipText';
import TextareaAutosize from 'react-textarea-autosize';

// https://formik.org/docs/api/useField
function InputTextArea(props) {
  const { tipText, type, value, ...rest } = props;
  const inputType = type || 'text';
  const [field, meta] = useField(props);
  const inputElement = useRef(null);

  const disabledClass = props.disabled ? 'disabled' : '';
  const className = `inputContainer ${disabledClass} ${
    props.className || ''
  } type-${inputType}`;
  const notEmptyClass = meta.value !== '' ? 'notEmpty' : '';
  const inputClassName = `input ${notEmptyClass}`;

  const tipTextExplain = () => {
    if (!tipText) {
      return null;
    } else {
      return <TipText>{tipText}</TipText>;
    }
  };

  return (
    <div className="InputTextArea">
      <div
        className={className}
        onClick={(_e) => {
          inputElement.current.focus();
        }}
      >
        <TextareaAutosize
          className={inputClassName}
          ref={inputElement}
          type={inputType}
          {...field}
          {...rest}
        >
          {value}
        </TextareaAutosize>
        <label>{props.label || props.name}</label>
      </div>
      {tipTextExplain()}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default InputTextArea;
