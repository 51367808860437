import './PublishingOptions.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DICTIONARY from './dictionary.json';
import Locale from '../../../../services/locale';

import HelpModal from '../../../../components/HelpModal/HelpModal';
import IconButton from '../../../../components/Button/IconButton';
import Icon from '../../../../components/Icon/Icon';
import mdToHtml from '../../../../services/markdown';

function PublishingOptions(props) {
  const SAY = new Locale([DICTIONARY]);
  const buttonSize = 42;
  const wipFalse = { publish: false, unpublish: false };
  const [wip, setWip] = useState(wipFalse);

  useEffect(() => {
    if (!props.updating) {
      setWip(wipFalse);
    }
    // eslint-disable-next-line
  }, [props.updating]);

  function onUnpublishClick() {
    setWip({ unpublish: true });
    if (props.onUnpublishClick) {
      props.onUnpublishClick();
    }
  }

  function onPublishClick() {
    setWip({ publish: true });
    if (props.onPublishClick) {
      props.onPublishClick();
    }
  }

  function renderHelpHtml() {
    return (
      <div className="PublishingOptionsHelp">
        <h2>{SAY.publishingOptions}</h2>

        <h3>
          {SAY.publish.label} <Icon name="publish" />
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: mdToHtml(SAY.publish.description),
          }}
        />
        <h3>
          {SAY.unpublish.label} <Icon name="unpublished" />
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: mdToHtml(SAY.unpublish.description),
          }}
        />
      </div>
    );
  }

  function renderPublishButtonHtml() {
    if (props.data.published) {
      return (
        <>
          <IconButton
            animateWhenDone
            onClick={() => onUnpublishClick()}
            size={buttonSize}
            icon="unpublished"
            theme="danger"
            loading={wip.unpublish || false}
          />
        </>
      );
    } else {
      return (
        <>
          <IconButton
            icon="publish"
            size={buttonSize}
            theme="success"
            onClick={() => onPublishClick()}
            loading={wip.publish || false}
          />
        </>
      );
    }
  }

  return (
    <div className="PublishingOptions">
      {renderPublishButtonHtml()}
      <HelpModal buttonLabel="OK" raw>
        {renderHelpHtml()}
      </HelpModal>
    </div>
  );
}

PublishingOptions.propTypes = {
  data: PropTypes.object,
};
export default PublishingOptions;
