import { getAllServiceKeys } from '../helpers/services';

export const extractFiltersFromThemes = (themes) => {
  const serviceKeys = getAllServiceKeys();
  const categories = {};
  for (let k of serviceKeys) {
    categories[k] = [];
  }
  const tags = {};
  for (let k of serviceKeys) {
    tags[k] = [];
  }

  for (let theme of themes) {
    for (let type of theme.types) {
      if (categories[theme.service].indexOf(type) < 0) {
        categories[theme.service].push(type);
      }
    }
    if (theme.tags && theme.tags.length > 0) {
      for (let tag of theme.tags) {
        if (tags[theme.service].indexOf(tag) < 0) {
          tags[theme.service].push(tag);
        }
      }
    }
  }

  return {
    categories,
    tags,
  };
};

export const filterCategoriesListFromFilterOptions = (
  categories,
  filterOptions,
  selectedService = 'all'
) => {
  let cleanFilterOptions = [];
  if (!selectedService || selectedService === 'all') {
    for (let k of Object.keys(filterOptions)) {
      cleanFilterOptions = [
        ...new Set([...cleanFilterOptions, ...filterOptions[k]]),
      ];
    }
  } else {
    cleanFilterOptions = filterOptions[selectedService];
  }

  const r = categories.filter((item) => {
    return (
      cleanFilterOptions.length === 0 ||
      item.value === 'all' ||
      cleanFilterOptions.indexOf(item.value) >= 0
    );
  });
  return r;
};

export const filterTagsListFromFilterOptions = (tags, filterOptions) => {
  return tags.filter((item) => {
    return filterOptions.indexOf(item.value) >= 0;
  });
};

export const getThemeByUuid = (themes, uuid) => {
  return themes.find((item) => {
    return item.uuid === uuid;
  })
};