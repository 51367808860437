import './InvitationGuestsPage.scss';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import Toolbar from '../../components/Toolbar/Toolbar';
import Loading from '../../components/Loading/Loading';
import Icon from '../../components/Icon/Icon';
import ROUTES from '../../services/routes/';
import { useGetGuestsForInvitation } from '../../services/data/hooks/guests';
import { getInvitationByUuid } from '../../services/data/helpers/invitations';
import DICTIONARY from './dictionary.json';
import Locale from '../../services/locale';
import { getGuestsForInvitation } from '../../services/data/storage/guests';

function InvitationGuestsPage() {
  const SAY = new Locale([DICTIONARY]);
  const { uuid } = useParams();
  const [invitation, setInvitation] = useState();
  const [guests, setGuests] = useState(null);
  const [initialGuests, loadingInitialGuests] = useGetGuestsForInvitation(uuid);

  useEffect(() => {
    setGuests(initialGuests);
    window.I2GO.engine.subscribe(refreshList);

    return function cleanup() {
      window.I2GO.engine.unsubscribe(refreshList);
    };
    // eslint-disable-next-line
  }, [initialGuests]);

  useEffect(() => {
    getInvitationByUuid(uuid).then((theInvitation) => {
      setInvitation(theInvitation);
    });
  }, [uuid]);

  function refreshList() {
    getGuestsForInvitation(uuid).then((updatedGuests) => {
      if (!_.isEqual(updatedGuests, guests)) {
        setGuests(updatedGuests);
      }
    });
  }

  function renderGuestsListHtml() {
    if (!guests && !loadingInitialGuests) {
      return null;
    } else if (loadingInitialGuests) {
      return <Loading />;
    } else {
      const byRsvp = {
        yes: [],
        no: [],
        maybe: [],
      };

      for (let guest of guests) {
        if (guest.rsvpResponse) {
          if (byRsvp[guest.rsvpResponse]) {
            byRsvp[guest.rsvpResponse].push(guest);
          }
        }
      }

      return Object.keys(byRsvp).map((rsvp, i) => {
        if (!byRsvp[rsvp].length) {
          return null;
        }
        const sortedList = _.sortBy(byRsvp[rsvp], [
          function (o) {
            return o.name;
          },
        ]);
        return (
          <div className="listSection" key={`listSecion_${i}`}>
            <h2>{SAY.rsvpTitles[rsvp]}</h2>
            <ul>
              {sortedList.map((g, key) => {
                const selfCode = g.code ? (
                  <span className="selfCode">({g.code})</span>
                ) : null;
                const selfEmail = g.email ? (
                  <span className="selfEmail">
                    <br />
                    {g.email}
                  </span>
                ) : null;
                return (
                  <li key={`guest_${key}`}>
                    <Icon name="check-circle" style={{ top: '10px' }} />
                    {g.name} {selfCode} {selfEmail}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      });
    }
  }

  function renderHtml() {
    const pageTitle = invitation ? invitation.title : '...';
    const subTitle = 'Guests';
    return (
      <div className="InvitationGuestsPage page">
        <Toolbar
          backTo={ROUTES.invitations}
          title={pageTitle}
          subTitle={subTitle}
        />
        <div className="guestsList">{renderGuestsListHtml()}</div>
      </div>
    );
  }

  return renderHtml();
}

export default InvitationGuestsPage;
