import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import mdToHtml from '../../services/markdown';
import HELPING_MASCOT from '../../themes/img/helping-mascot.png';
import Button from '../Button/OutlineButton';
import IconButton from '../Button/IconButton';

function WishItemSearchHelpModal(props) {
  const [isOpen, setIsOpen] = useState(false);

  function makeContent() {
    if (props.raw) {
      return props.children;
    } else {
      return (
        <div dangerouslySetInnerHTML={{ __html: mdToHtml(props.children) }} />
      );
    }
  }

  return (
    <>
      {!props.hideButton && (
        <IconButton
          className="helpButton"
          icon="help-outline"
          onClick={() => setIsOpen(true)}
          size="32"
          theme="secondary"
        />
      )}
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        style={{
          content: { inset: '16px', maxWidth: '600px', margin: '0 auto' },
        }}
      >
        <div>
          <img
            alt="(?)"
            src={HELPING_MASCOT}
            style={{ width: '50px', height: '50px', float: 'right' }}
          />
        </div>
        {makeContent()}
        <div
          style={{
            textAlign: 'center',
            marginTop: '16px',
            borderTop: '1px solid gray',
            padding: '8px',
          }}
        >
          <Button onClick={() => setIsOpen(false)}>{props.buttonLabel}</Button>
        </div>
      </Modal>
    </>
  );
}

WishItemSearchHelpModal.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
  raw: PropTypes.bool,
};

export default WishItemSearchHelpModal;
