import { makeApiUrl } from '.';
import { apiGet } from './ajax';
import { DEFAULT_LANGUAGE } from '../';
import { readPackedRepo } from '../storage';

export const fetchHomePage = () => {
  return new Promise((resolve) => {
    readPackedRepo().then((repo) => {
      const activeProfile = repo.profiles.find((item) => {
        return item.uuid === repo.activeProfile;
      });
      const language = activeProfile
        ? activeProfile.language
        : DEFAULT_LANGUAGE;
      apiGet({
        url: makeApiUrl('apphome') + `?lang=${language}`,
        headers: { 'Content-Type': 'text/html' },
      })
        .then((response) => {
          if (!response.ok || response.status >= 300) {
            return resolve('');
          } else {
            return resolve(response.html);
          }
        })
        .catch(() => {
          return resolve('');
        });
    });
  });
};
