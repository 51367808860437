import generateUuid from '../uuid';
import { base64Encode, base64Decode } from '../base64';
import { readRepo, writeRepo } from '.';

export const explodeDeviceId = (base64) => {
  const decoded = base64Decode(base64);
  const [appUuid, deviceUuid] = decoded.split('.');
  return {
    appUuid,
    deviceUuid,
  };
};

export const getDeviceId = () => {
  return new Promise((resolve, reject) => {
    readDeviceId()
      .then((deviceId) => {
        if (!deviceId) {
          const appUuid = generateUuid();
          const deviceUuid = generateUuid();
          deviceId = implodeDeviceId({ appUuid, deviceUuid });
          writeDeviceId(deviceId)
            .then(() => {
              return resolve(deviceId);
            })
            .catch((error) => {
              return reject(error);
            });
        }
        return resolve(deviceId);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

const implodeDeviceId = ({ appUuid, deviceUuid }) => {
  return base64Encode(`${appUuid}.${deviceUuid}`);
};

export const readDeviceId = () => {
  return readRepo('deviceId');
};

export const writeDeviceId = (value) => {
  return writeRepo('deviceId', value);
};
