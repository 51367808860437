import { useState, useEffect } from 'react';
import { getWishItemByUuid, getWishItems } from '../helpers/wish-items';
import { getInvitationByUuid } from '../helpers/invitations';
import { getGuestActions } from '../storage/guests';

export const useGetWishItemByUuid = (uuid) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getWishItemByUuid(uuid)
        .then((theEntity) => {
          setLoading(false);
          setData(theEntity);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, [uuid]);

  return [data, loading, status];
};

export const useGetWishItemsByCategoryUuid = (categoryUuid) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getWishItems()
        .then((recordset) => {
          const filtered = recordset.filter((item) => {
            return item.category_uuid === categoryUuid;
          });
          setLoading(false);
          setData(filtered);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
    // eslint-disable-next-line
  }, []);

  return [data, loading, status];
};

export const useGetWishItemsList = () => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getWishItems()
        .then((recordset) => {
          setLoading(false);
          setData(recordset);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, []);

  return [data, loading, status];
};

export const useGetWishItemReservation = (wishItemUuid) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getGuestActions()
        .then((actions) => {
          if (actions) {
            const reservations = actions.filter((action) => {
              return (
                action.actionCode === 'wishlist' &&
                action.actionValue === wishItemUuid
              );
            });
            if (reservations.length > 0) {
              const invitationUuid = reservations[0].invitationUuid;
              getInvitationByUuid(invitationUuid)
                .then((invitation) => {
                  setLoading(false);
                  setData(invitation);
                })
                .catch((error) => {
                  setLoading(false);
                  setStatus({
                    ok: false,
                    error,
                  });
                });
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, [wishItemUuid]);

  return [data, loading, status];
};
