import './InvitationPosts.scss';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OnBoard from '../../../../components/OnBoard/OnBoard';
import DICTIONARY from './dictionary.json';
import Locale from '../../../../services/locale';
import InvitationPostCard from './InvitationPostCard';
import _ from 'lodash';

function InvitationPosts(props) {
  const SAY = new Locale([DICTIONARY]);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (props.invitation) {
      setPosts(props.invitation.posts || []);
    }
  }, [props.invitation]);

  function onDelete(postUuid) {
    if (props.onDeletePost) {
      props.onDeletePost(postUuid);
    }
  }

  function renderPostsHtml() {
    if (!posts || !posts.length) {
      return <OnBoard markdown>{SAY.invitatinPostsOnBoard}</OnBoard>;
    }
    const sorted = _.orderBy(posts, ['timestamp'], ['desc']);
    return (
      <>
        <h3>{SAY.previousPosts}</h3>
        {sorted.map((post, i) => {
          return (
            <InvitationPostCard
              data={post}
              key={`invitationPost${i}`}
              onDeleteClick={onDelete}
            />
          );
        })}
      </>
    );
  }

  return <div className="InvitationPosts">{renderPostsHtml()}</div>;
}

InvitationPosts.propTypes = {
  invitation: PropTypes.object.isRequired,
  onDeletePost: PropTypes.func,
};

export default InvitationPosts;
