import { LOCALIZED_MOMENT } from './';

export const dateToTimestamp = (date) => {
  return (
    date.getFullYear() +
    '-' +
    ('00' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + date.getDate()).slice(-2) +
    ' ' +
    ('00' + date.getHours()).slice(-2) +
    ':' +
    ('00' + date.getMinutes()).slice(-2) +
    ':' +
    ('00' + date.getSeconds()).slice(-2)
  );
};

export const formattedDate = (value) => {
  const m = LOCALIZED_MOMENT(value);
  if (!m.isValid()) {
    return value || <br />;
  }
  return m.format('LL');
};

export const formattedTime = (value) => {
  const m = LOCALIZED_MOMENT(value, 'HH:mm');
  if (!m.isValid()) {
    return value || <br />;
  }
  return m.format('LT');
};
