import './ProfileAvatar.scss';
import Avatar from '../../../../components/Avatar/Avatar';
import IconButton from '../../../../components/Button/IconButton';

function ProfileAvatar(props) {
  function editButton() {
    return (
      <div className="editorButton">
        <IconButton
          icon="mode-edit"
          onClick={() => onEditClick()}
          theme="secondary"
        ></IconButton>
      </div>
    );
  }

  function onEditClick() {
    if (props.onEditClick) {
      props.onEditClick();
    }
  }

  return (
    <div className="ProfileAvatar">
      {props.forEdit && editButton()}
      <Avatar name={props.name} />
    </div>
  );
}

export default ProfileAvatar;
