import './InvitationOutbox.scss';
import { PropTypes } from 'prop-types';
import { useRef, useState } from 'react';
import IconButton from '../../../../components/Button/IconButton';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import InputText from '../../../../components/Form/InputText';
import { updateOutboxEntryName } from '../../../../services/data/helpers/invitations';

function InvitationOutboxCard(props) {
  const [data, setData] = useState(props.entry);
  const [mode, setMode] = useState('view');
  const [savingChanges, setSavingChanges] = useState(false);
  const invitationUrlRef = useRef();
  const iconSize = 32;

  function onCopyUrlToClipboard() {
    invitationUrlRef.current.select();
    const url = invitationUrlRef.current.value;
    navigator.clipboard.writeText(url);
    toast.success(`OK, ${url}`, { autoClose: 1000 });
  }

  function onRemove() {
    if (props.onRemoveClick) {
      props.onRemoveClick(data.uuid);
    }
  }

  function onSetEditMode() {
    setMode('edit');
  }

  function onShareUrl() {
    navigator.share({ url: props.outboxUrl });
  }

  function onSubmit({ guestName }) {
    setSavingChanges(true);
    updateOutboxEntryName(
      props.invitationUuid,
      data.uuid,
      guestName
    ).then(() => {
      const newData = Object.assign({}, data, {name: guestName});
      setSavingChanges(false);
      setData(newData);
      setMode('view');
    });
  }

  function renderFormHtml() {
    return (
      <Formik
        initialValues={{
          guestName: data.name,
        }}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <form>
            <div className="oubtoxEntryBox" key={data.uuid}>
              <div className="entryData">
                <InputText formik={formik} name="guestName" autoComplete="off" />
              </div>
              <div className="entryOptions">
                <IconButton
                  loading={savingChanges}
                  icon="save"
                  onClick={formik.handleSubmit}
                  size={iconSize}
                  theme="primary"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }

  function renderShareButtonsHtml() {
    if (!props.invitationPublished) {
      return null;
    } else {
      return (
        <>
          <IconButton
            icon="share"
            noBorder
            onClick={onShareUrl}
            size={iconSize}
            theme="outline primary"
          />
          <IconButton
            icon="content-copy"
            noBorder
            onClick={onCopyUrlToClipboard}
            size={iconSize}
            theme="outline primary"
          />
        </>
      );
    }
  }

  function renderRowHtml() {
    if (mode === 'edit') {
      return renderFormHtml();
    } else {
      return (
        <div className="oubtoxEntryBox" key={data.uuid}>
          <div className="entryData">
            <pre className="entryNum">
              {String(props.index + 1).padStart(3)}
            </pre>
            <span className="entryName">{data.name}</span>
            <span className="entryUuid">{data.uuid}</span>
            <input
              ref={invitationUrlRef}
              type="hidden"
              value={props.outboxUrl}
            />
          </div>
          <span className="entryOptions">
            {renderShareButtonsHtml()}
            {!props.viewOnly && (
              <>
                <IconButton
                  icon="mode-edit"
                  noBorder
                  onClick={onSetEditMode}
                  size={iconSize}
                  theme="outline success"
                />
                <IconButton
                  icon="close"
                  noBorder
                  onClick={onRemove}
                  size={iconSize}
                  theme="outline danger"
                />
              </>
            )}
          </span>
        </div>
      );
    }
  }

  return <div className="InvitationOutboxCard">{renderRowHtml()}</div>;
}

InvitationOutboxCard.propTypes = {
  invitationPublished: PropTypes.bool.isRequired,
  invitationUuid: PropTypes.string.isRequired,
  outboxUrl: PropTypes.string.isRequired,
};

export default InvitationOutboxCard;
