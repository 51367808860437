import './FormLine.scss';

function FormLine(props) {
  const divClassName = `FormLine ${props.className || ''}`;
  const { style } = props;
  return (
    <div style={style || null} className={divClassName}>
      {props.children}
    </div>
  );
}

export default FormLine;
