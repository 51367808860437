import './WishItemEditorPage.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Locale from '../../services/locale';
import DICTIONARY from './dictionary.json';
import Toolbar from '../../components/Toolbar/Toolbar';
import ROUTES from '../../services/routes';
import WishItemForm from './components/WishItemForm/WishItemForm';
import Loading from '../../components/Loading/Loading';
import generateUuid from '../../services/data/helpers/uuid';
import {
  addWishItem,
  deleteWishItemByUuid,
  resetWishItemDraft,
  setWishItemDraft,
  updateWishItem,
} from '../../services/data//helpers/wish-items';
import { useGetWishItemByUuid } from '../../services/data/hooks/wish-items';
import ToolbarButtonBack from '../../components/Toolbar/components/ToolbarButtonBack/ToolbarButtonBack';

function WishItemEditorPage() {
  const SAY = new Locale([DICTIONARY]);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [mode] = useState(!uuid || uuid === 'new' ? 'create' : 'edit');
  const [theWishItem, loadingWishItem] = useGetWishItemByUuid(uuid);
  const [data, setData] = useState();

  useEffect(() => {
    if (!loadingWishItem && theWishItem) {
      setData(theWishItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theWishItem]);

  function cleanDraftAndGoBack() {
    resetWishItemDraft();
    navigateBack();
  }

  function navigateBack() {
    navigate(ROUTES.wishItems);
  }

  function onDelete(uuid) {
    deleteWishItemByUuid(uuid).then(() => {
      navigateBack();
    });
  }

  function onFormCancel() {
    updateDraft(null);
    navigateBack();
  }

  function onFormChange(formData) {
    const newData = Object.assign({}, data, formData);
    setData(newData);
    if (mode === 'create') {
      updateDraft(newData);
    }
  }

  function onSubmit(values) {
    if (mode === 'create') {
      values.uuid = generateUuid();
      addWishItem(values)
        .then(() => {
          resetWishItemDraft();
          navigateBack();
        })
        .catch((error) => console.error('ERROR', error));
    } else {
      updateWishItem(uuid, values)
        .then(() => {
          resetWishItemDraft();
          navigateBack();
        })
        .catch((error) => console.error('ERROR', error));
    }
  }

  function updateDraft(data) {
    setWishItemDraft(data);
  }

  function renderToolbar() {
    const subTitle = mode === 'create' ? SAY.createNew : SAY.edit;
    return (
      <Toolbar
        backButton={<ToolbarButtonBack onClick={cleanDraftAndGoBack} />}
        title={SAY.wishItems}
        subTitle={subTitle}
        enableMenu
        withMenu
        withSidebar
      />
    );
  }

  function renderView() {
    if (!data) {
      return <Loading />;
    } else {
      return (
        <WishItemForm
          data={data}
          forEdit={mode === 'edit'}
          onCancel={() => onFormCancel()}
          onChange={(newValues) => onFormChange(newValues)}
          onDelete={({ uuid }) => onDelete(uuid)}
          onSubmit={onSubmit}
        />
      );
    }
  }

  return (
    <div className="pageWithMenuLayout">
      {renderToolbar()}
      <div className="WishItemEditorPage page withButtonsBar">
        {renderView()}
      </div>
    </div>
  );
}

export default WishItemEditorPage;
