import { useState, useEffect } from 'react';
import { getInvitationByUuid, getInvitationDraft, getInvitations } from '../helpers/invitations';

export const useGetInvitationByUuid = (uuid) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getInvitationByUuid(uuid)
        .then((theEntity) => {
          setLoading(false);
          setData(theEntity);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, [uuid]);

  return [data, loading, status];
};

export const useGetInvitationDraft = () => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getInvitationDraft()
        .then((recordset) => {
          setLoading(false);
          setData(recordset);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, []);

  return [data, loading, status];
};

export const useGetInvitationsList = () => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getInvitations()
        .then((recordset) => {
          setLoading(false);
          setData(recordset);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, []);

  return [data, loading, status];
};
