import './InputImageUrl.scss';
import { useEffect, useState } from 'react';
import InputText from './InputText';

function InputImageUrl({ field, form, ...props }) {
  // const [previewExpanded, setPreviewExpanded] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const { autoComplete, type, ...rest } = props;

  useEffect(() => {
    if (field && field.value && field.value.length > 0) {
      setShowPreview(true);
    }
  }, [field]);

  function customOnChange(evt) {
    setShowPreview(evt.target.value.length > 0);
    field.onChange(evt);
  }

  function renderPreviewImage() {
    if (!showPreview || !field || !field.value || field.value.length < 1) {
      return null;
    } else {
      return (
        <div className="inputImagePreview">
          <img src={field.value} alt={field.value} />
        </div>
      );
    }
  }

  return (
    <div className="InputImageUrl">
      <InputText
        autoComplete="off"
        // label={props.label}
        type="text"
        {...field}
        onChange={customOnChange}
        {...rest}
      />
      {renderPreviewImage()}
    </div>
  );
}

export default InputImageUrl;
