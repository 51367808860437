import { readRepo, writeRepo } from './';
import { getDefaultCountryCode } from '../helpers/countries';
import { setDirty } from './';

export const DEFAULT_SETTINGS = {
  emailAddress: '',
  emailNotifications: true,
  country: getDefaultCountryCode(),
  language: 'en',
  zone: '',
};

export const getAccountSettings = () => {
  return new Promise((resolve) => {
    readAccountSettings().then((settings) => {
      return resolve(settings);
    });
  });
};

const readAccountSettings = () => {
  return new Promise((resolve) => {
    readRepo('settings').then((settings) => {
      if (!settings) {
        writeSettings(DEFAULT_SETTINGS).then((newSettings) => {
          return resolve(newSettings);
        });
      } else {
        return resolve(settings);
      }
    });
  });
};

export const setSettings = (values) => {
  return writeSettings(values);
};

export const writeSettings = (values) => {
  return new Promise((resolve) => {
    writeRepo('settings', values).then(() => {
      setDirty().then(() => {
        return resolve(values);
      });
    });
  });
};
