import './ThemeCard.scss';
import { useState } from 'react';
import DICTIONARY from './dictionary.json';
import Locale from '../../services/locale';
import Card from '../Card/Card';
import Button from '../Button/OutlineButton';
import LinkButton from '../Button/LinkButton';
import { themePreviewUrl } from '../../services/url';

function ThemeCard(props) {
  const SAY = new Locale(DICTIONARY);
  const [viewOnly] = useState(props.viewOnly || false);

  function cardHtml() {
    if (!props.data) {
      return null;
    } else {
      return (
        <Card>
          {/* <CardMedia style={{ backgroundImage: `url(${props.data.imageUrl})` }} /> */}
          <div className="themeImage">
            <img src={props.data.image_url} alt={props.data.locale_name} />
          </div>
          <div className="themeName">{props.data.locale_name}</div>
          <div className="themeTypes">{typesList()}</div>
          {!props.hidePrice && props.data.price_amount > 0.0 && (
            <div className="themePrice">{props.data.price_amount}</div>
          )}
          {!props.hidePrice && props.data.price_amount <= 0.0 && (
            <div className="themePrice">{SAY.free}</div>
          )}
          <div className="buttonPreview">
            <LinkButton
              theme="primary"
              trailingIcon="open-in-new"
              href={themePreviewUrl(SAY.getLanguage(), props.data.uuid)}
              rel="noopener, noreferrer"
              target="_blank"
            >
              {SAY.preview}
            </LinkButton>
          </div>
          {!viewOnly && (
            <div className="buttonChange">
              <Button
                theme="secondary"
                trailingIcon="chevron-right"
                onClick={() => onThemeSelect(props.data)}
              >
                {SAY.useThisTheme}
              </Button>
            </div>
          )}
          {specs()}
        </Card>
      );
    }
  }
  function onThemeSelect(data) {
    if (props.onThemeSelect) {
      props.onThemeSelect(data);
    }
  }

  function specs() {
    if (!props.data || props.hideAuthor) {
      return null;
    } else {
      if (props.data.author_name === props.data.designer_name) {
        return (
          <div className="themeSpecs">
            <p>
              <strong>{SAY.authorAndDesigner}</strong>
              <br />
              {props.data.author_name}
              {props.data.author_email && `(${props.data.author_email})`}
            </p>
          </div>
        );
      } else {
        return (
          <div className="themeSpecs">
            <p>
              <strong>{SAY.author}</strong>
              <br />
              {props.data.author_name}
              {props.data.author_email && `(${props.data.author_email})`}
            </p>
            <p>
              <strong>{SAY.designer}</strong>
              <br />
              {props.data.designer_name}
              {props.data.designer_email && `(${props.data.designer_email})`}
            </p>
          </div>
        );
      }
    }
  }

  function typesList() {
    if (
      !props.data ||
      !props.data.types ||
      props.data.types.length < 1 ||
      !props.eventTypes
    ) {
      return null;
    }
    return props.data.types.map((typeName, i) => {
      return (
        <span key={i} className="typeName">
          {props.eventTypes[typeName][SAY.getLanguage()]}
        </span>
      );
    });
  }

  function renderThemeCardHtml() {
    const className = 'ThemeCard' + (props.row ? ' rowLayout': '');
    return <div className={className}>{cardHtml()}</div>
  }

  return renderThemeCardHtml();
}

export default ThemeCard;
