import HardError from './HardError';
import mdToHtml from '../../services/markdown';
import DICTIONARY from './dictionary.json';
import Locale from '../../services/locale';

function NetworkError(props) {
  const SAY = new Locale(DICTIONARY);
  const html = mdToHtml(SAY.networkError);
  return (
    <HardError>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </HardError>
  );
}

export default NetworkError;
