import { readRepo, writeRepo } from '.';

export const getAppVersion = () => {
  return readAppVersion();
};

export const readAppVersion = () => {
  return readRepo('appVersion');
};

export const setAppVersion = (v) => {
  return writeAppVersion(v);
};

export const writeAppVersion = (value) => {
  return writeRepo('appVersion', value);
};
