import './InvitationPosts.scss';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import IconButton from '../../../../components/Button/IconButton';
import mdToHtml from '../../../../services/markdown';
import DICTIONARY from './dictionary.json';
import Locale from '../../../../services/locale';

function InvitationPostCard(props) {
  const SAY = new Locale([DICTIONARY]);
  const iconSize = 32;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  function onDelete() {
    if (props.onDeleteClick) {
      props.onDeleteClick(data.uuid);
    }
  }

  function renderRowHtml() {
    if (!data) {
      return null;
    }
    const asHtml = mdToHtml(data.content);
    console.log(data);
    return (
      <div className="postEntryBox" key={data.uuid}>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: asHtml }}
        ></div>
        <div className="tools">
          <span className="timestamp">{data.timestamp}</span>
          <span className={`level ${data.level}`}>
            {SAY.postForm.levels[data.level]}
          </span>
          <span className="entryOptions">
            <IconButton
              icon="close"
              noBorder
              onClick={onDelete}
              size={iconSize}
              theme="outline danger"
            />
          </span>
        </div>
      </div>
    );
  }

  return <div className="InvitationPostCard">{renderRowHtml()}</div>;
}

InvitationPostCard.propTypes = {
  data: PropTypes.object,
  onDeleteClick: PropTypes.func,
};

export default InvitationPostCard;
