import './ToolbarButton.scss';

function ToolbarButton(props) {
  const className = `ToolbarButton ${props.className || ''}`;

  function onClick(_evt) {
    if (props.onClick) {
      props.onClick(_evt);
    }
  }

  return (
    <div className={className} onClick={(evt) => onClick(evt)}>
      {props.children}
    </div>
  );
}

export default ToolbarButton;
