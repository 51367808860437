import './ThemeRadioFilter.scss';
import { useState, useEffect } from 'react';
import Icon from '../../../Icon/Icon';
import ThemeFilterGroup from '../ThemeFilterGroup/ThemeFilterGroup';

function ThemeRadioFilter(props) {
  const [selectedOption, setSelectedOption] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const foundOption = props.options.find((item) => {
      return item.value === props.value;
    });
    if (foundOption) {
      setSelectedOption(foundOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  function onChange(name, value) {
    updateSelectedOption(value);
    if (props.onChange) {
      props.onChange(name, value);
    }
  }

  function updateSelectedOption(value) {
    const option = props.options.find((item) => {
      return item.value === value;
    });
    if (option) {
      setSelectedOption(option);
      setOpen(false);
    }
  }

  function renderControl() {
    const renderButton = () => {
      if (!selectedOption) {
        return null;
      } else {
        const openClass = open ? 'open ' : '';
        const className = `radioSwitch ${openClass}`;
        return (
          <div className={className}>
            <div className="value" onClick={() => setOpen(!open)}>
              <Icon name="filter" />
              {selectedOption.label}
            </div>
            <ThemeFilterGroup
              className={openClass}
              name={props.name}
              onChange={onChange}
              options={props.options}
              value={selectedOption.value}
            />
          </div>
        );
      }
    };

    return renderButton();
  }

  return <div className="ThemeRadioFilter">{renderControl()}</div>;
}

export default ThemeRadioFilter;
