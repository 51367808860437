import { Link } from 'react-router-dom';
import Icon from '../../../../components/Icon/Icon';
import ROUTES from '../../../../services/routes';
import ToolbarButton from '../ToolbarButton/ToolbarButton';

function ToolbarButtonHome() {
  return (
    <Link className="ToolbarButtonHome" to={ROUTES.home}>
      <ToolbarButton>
        <Icon name="home" />
      </ToolbarButton>
    </Link>
  );
}

export default ToolbarButtonHome;
