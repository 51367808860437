import { useState, useEffect } from 'react';
import { getActiveProfile, getAllProfiles, getIdentifiedProfile } from '../helpers/profiles';

export const useActiveProfile = () => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getActiveProfile()
        .then((profile) => {
          setLoading(false);
          setData(profile);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, []);

  return [data, loading, status];
};

export const useIdentifiedProfile = (uuid) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run(uuid) {
      setLoading(true);
      getIdentifiedProfile(uuid)
        .then((profile) => {
          if (!profile) {
            setStatus({ ok: false });
          }
          setLoading(false);
          setData(profile);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run(uuid);
  }, [uuid]);

  return [data, loading, status];
};

export const useProfilesList = () => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getAllProfiles()
        .then((profiles) => {
          setLoading(false);
          setData(profiles);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, []);

  return [data, loading, status];
};
