import PropTypes from 'prop-types';
import './LinkButton.scss';
import Icon from '../Icon/Icon';

function LinkButton(props) {
  const { trailingIcon, icon, ...rest } = props;

  const theme = props.theme || 'primary';
  const className = (props.className || 'LinkButton') + ` ${theme}`;
  const leadingIconHtml = icon ? (
    <span className="leadingIcon">
      <Icon name={icon} />
    </span>
  ) : null;
  const trailingIconHtml = trailingIcon ? (
    <span className="trailingIcon">
      <Icon name={trailingIcon} />
    </span>
  ) : null;
  return (
    <a
      className={className}
      href={props.href}
      style={props.style || null}
      {...rest}
    >
      {leadingIconHtml}
      <span>{props.children}</span>
      {trailingIconHtml}
    </a>
  );
}

LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
};

export default LinkButton;
