import './HardError.scss';

function HardError(props) {
  return (
    <div className="HardError">
      <div className="content">{props.children}</div>
    </div>
  );
}

export default HardError;
