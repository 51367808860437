import './ThemeFiltersModalControls.scss';
import DICTIONARY from './dictionary.json';
import Locale from '../../../../../../services/locale';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ThemeFilterGroup from '../../../ThemeFilterGroup/ThemeFilterGroup';
import Button from '../../../../../../components/Button/Button';
import { filterName } from '../../../../../../services/data/helpers/theme-filters';
import mdToHtml from '../../../../../../services/markdown';

function ThemeFiltersModalControls(props) {
  const SAY = new Locale([DICTIONARY]);

  function closeModal() {
    if (props.onClose) {
      props.onClose();
    }
  }

  function onRadioChange(group, value) {
    if (props.onChange) {
      props.onChange(group, value);
    }
  }

  function renderFilterByServiceInput() {
    if (!props.lockedService) {
      return (
        <ThemeFilterGroup
          name="service"
          onChange={onRadioChange}
          options={props.services}
          value={props.filters.service || 'all'}
        />
      );
    } else {
      const serviceName = filterName(props.services, props.filters.service);
      const asHtml = mdToHtml(SAY.formatString(SAY.lockedService, serviceName));
      return <div dangerouslySetInnerHTML={{ __html: asHtml }} />;
    }
  }

  const customStyles = {
    content: {
      boxSizing: 'border-box',
      padding: '0',
      border: 'none',
      borderRadius: '0',
      overflowY: 'auto',
      margin: '0 auto',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: props.width || '100%',
      height: '100%',
      maxWidth: props.maxWidth || '1000px',
      outline: 'none',
    },
  };
  return (
    <div className="ThemeFiltersModalControls">
      <Modal
        ariaHideApp={false}
        className="ThemeFiltersModalControls"
        isOpen={props.isOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <form>
          <div className="filtersPanel buttons">
            <Button theme="primary" onClick={closeModal}>
              {SAY.apply}
            </Button>
          </div>
          <div className="filtersPanel">{renderFilterByServiceInput()}</div>
          <div className="filtersPanel">
            <ThemeFilterGroup
              name="category"
              onChange={onRadioChange}
              options={props.categories}
              value={props.filters.category || 'all'}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

ThemeFiltersModalControls.propTypes = {
  filters: PropTypes.object.isRequired,
  lockedService: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
};

export default ThemeFiltersModalControls;
