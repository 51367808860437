function ThemeFilterGroup(props) {
  function onChange(evt) {
    if (props.onChange) {
      props.onChange(props.name, evt.target.value);
    }
  }

  function renderOptions() {
    return props.options.map((item, i) => {
      const checked = item.value === props.value;
      return (
        <div className="option" key={i}>
          <label>
            <input
              checked={checked}
              name={props.name}
              onChange={onChange}
              type="radio"
              value={item.value}
            />
            {item.label}
          </label>
        </div>
      );
    });
  }

  const className = `ThemeFilterGroup ${props.className || ''}`;
  return <div className={className}>{renderOptions()}</div>;
}

export default ThemeFilterGroup;
