import './AvatarSelectorGrid.scss';
import Loading from '../../../../components/Loading/Loading';
// import Avatar from '../../../../components/Avatar/Avatar';
import AvatarCard from './components/AvatarCard/AvatarCard';

function AvatarSelectorGrid(props) {
  function avatarsGrid() {
    if (!props.avatars) {
      return <Loading />;
    } else {
      return props.avatars.map((item) => {
        return (
          <AvatarCard
            onClick={() => onClick(item)}
            key={item.name}
            data={item}
          />
        );
      });
    }
  }

  function onClick(avatar) {
    if (props.onAvatarSelect) {
      props.onAvatarSelect(avatar.name);
    }
  }

  return <div className="AvatarSelectorGrid">{avatarsGrid()}</div>;
}

export default AvatarSelectorGrid;
