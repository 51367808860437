import './OnBoard.scss';
import PropTypes from 'prop-types';
import mdToHtml from '../../services/markdown';

function OnBoard(props) {
  function renderContentHtml() {
    if (props.markdown) {
      const html = mdToHtml(props.children);
      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }
    return props.children;
  }

  return (
    <div className="OnBoard">
      <div className="content">{renderContentHtml()}</div>
    </div>
  );
}

OnBoard.propTypes = {
  markdown: PropTypes.bool,
};

export default OnBoard;
