import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Locale from '../../services/locale';
import DICTIONARY from './dictionary.json';
import Toolbar from '../../components/Toolbar/Toolbar';
import ButtonsBar from '../../components/ButtonsBar/ButtonsBar';
import IconButton from '../../components/Button/IconButton';
import Loading from '../../components/Loading/Loading';
import InvitationsList from './components/InvitationsList/InvitationsList';
import ROUTES, { makePath } from '../../services/routes';
import {
  useGetInvitationsList,
  useGetInvitationDraft,
} from '../../services/data/hooks/invitations';
import { useGetThemes } from '../../services/data/hooks/themes';

const OnBoard = React.lazy(() =>
  import('./components/InvitationsOnBoard/InvitationsPageOnBoard')
);

function InvitationsPage() {
  const SAY = new Locale(DICTIONARY);
  const [invitations, loadingInvitations] = useGetInvitationsList();
  const [invitationDraft, loadingInvitationDraft] = useGetInvitationDraft();
  const [themes] = useGetThemes();
  const navigate = useNavigate();

  function onCheckout(data) {
    navigate(makePath(ROUTES.checkout, { uuid: data.uuid }));
  }

  function onCardEdit(data) {
    navigate(makePath(ROUTES.invitationEditor, { uuid: data.uuid }));
  }

  function onCreateClick() {
    navigate(makePath(ROUTES.invitationEditor, { uuid: 'new' }));
  }

  function onInvitationSelect(theInvitation) {
    console.log('onInvitationSelect', theInvitation);
  }

  function renderListHtml() {
    const loadingData = loadingInvitations;
    if (loadingData) {
      return <Loading />;
    } else if (!invitations || !invitations.length) {
      return (
        <Suspense fallback={<Loading />}>
          <OnBoard />
        </Suspense>
      );
    } else {
      const themesData = themes && themes.data ? themes.data : [];
      return (
        <InvitationsList
          data={invitations}
          themes={themesData || []}
          onCheckout={(card) => onCheckout(card)}
          onCardEdit={(card) => onCardEdit(card)}
          onInvitationSelect={(theInvitation) =>
            onInvitationSelect(theInvitation)
          }
        />
      );
    }
  }

  function renderBadgeHtml() {
    if (
      loadingInvitationDraft ||
      !invitationDraft ||
      invitationDraft === null
    ) {
      return null;
    } else {
      return <div className="addButtonBadge">{SAY.draft}</div>;
    }
  }

  return (
    <div className="pageWithMenuLayout">
      <Toolbar
        backTo={ROUTES.home}
        title={SAY.invitations}
        enableMenu
        withMenu
        withSidebar
      />
      <div className="InvitationsPage page withButtonsBar">
        {renderListHtml()}
        <ButtonsBar
          fixed
          main={
            <IconButton large icon="add" onClick={onCreateClick}>
              {renderBadgeHtml()}
            </IconButton>
          }
        />
      </div>
    </div>
  );
}

export default InvitationsPage;
