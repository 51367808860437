import './MainMenu.scss';
import { Link, useLocation } from 'react-router-dom';
import { buildMenuOptions } from './main-menu-options';
import NAME_LOGO from './name-logo.svg';
import { useActiveProfile } from '../../services/data/hooks/profiles';

function MainMenu(props) {
  const location = useLocation();
  const fixed = props.fixed ? ' fixed' : '';
  const className = `MainMenu ${props.className || ''} ${fixed}`;
  const [activeProfile, loadingActiveProfile] = useActiveProfile();

  const renderProfilesMenu = () => {
    if (loadingActiveProfile) {
      return <>Loading...</>;
    }
    return (
      <ul>
        {buildMenuOptions(activeProfile).map((item, i) => {
          if (!item || !item.href) {
            return false;
          }
          const className = location.pathname === item.href ? 'current' : null;
          return (
            <li className={className} key={i}>
              <Link to={item.href}>{item.name}</Link>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className={className}>
      <div className="logo">
        <img src={NAME_LOGO} alt="Invitogo" />
      </div>
      {renderProfilesMenu()}
    </div>
  );
}

export default MainMenu;
