import './WishItemCard.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Card from '../../../../components/Card/Card';
import IconButton from '../../../../components/Icon/Icon';
import ImageZoomModal from '../../../../components/ImageZoomModal/ImageZoomModal';
import Locale from '../../../../services/locale';
import DICTIONARY from './dictionary.json';
import { useGetWishCategoryByUuid } from '../../../../services/data/hooks/wish-categories';
import { useGetWishItemReservation } from '../../../../services/data/hooks/wish-items';
import { useEffect } from 'react';

function WishItemCard(props) {
  const [zoomImageOpen, setZoomImageOpen] = useState(false);
  const [category] = useGetWishCategoryByUuid(props.data.category_uuid);
  const [categoryName, setCategoryName] = useState();
  const [onInvitation, setOnInvitation] = useState();
  const [reservedOnInvitation] = useGetWishItemReservation(props.data.uuid);
  const SAY = new Locale([DICTIONARY]);

  useEffect(() => {
    if (category) {
      setCategoryName(category.name);
    } else {
      setCategoryName(null);
    }

    if (reservedOnInvitation) {
      setOnInvitation(reservedOnInvitation);
    }
  }, [category, reservedOnInvitation]);

  function onCardEdit() {
    if (props.onEdit) {
      props.onEdit(props.data);
    }
  }

  function renderCategoryNameHtml() {
    if (!categoryName) {
      return null;
    } else {
      return <div className="categoryName">{categoryName}</div>;
    }
  }

  function renderReservedOnHtml() {
    if (!onInvitation) {
      return null;
    } else {
      const titleHtml = <span className="title">{onInvitation.title}</span>;
      return (
        <div className="reservedIn">
          {SAY.formatString(SAY.reservedIn, titleHtml)}
        </div>
      );
    }
  }

  function renderCardHtml() {
    if (!props.data) {
      return null;
    } else {
      return (
        <Card>
          <ImageZoomModal
            isOpen={zoomImageOpen}
            onClose={() => setZoomImageOpen(false)}
            src={props.data.image_url}
            alt={props.data.name}
          />
          <div
            className="wishItemImage"
            onClick={() => setZoomImageOpen(true)}
            style={{ backgroundImage: `url(${props.data.image_url})` }}
          />
          <div className="wishItemInfo">
            <div className="name">{props.data.name}</div>
            {renderCategoryNameHtml()}
            {renderReservedOnHtml()}
          </div>
          <div className="wishItemButtons">
            <IconButton
              clickable
              name="mode-edit"
              onClick={onCardEdit}
              // size="32"
            ></IconButton>
          </div>
        </Card>
      );
    }
  }

  return <div className="WishItemCard">{renderCardHtml()}</div>;
}

WishItemCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WishItemCard;
