import './ProfilesList.scss';
import ProfileCard from '../ProfileCard/ProfileCard';

function ProfilesList(props) {
  function onCardClick(theProfile) {
    if (props.onProfileSelect) {
      props.onProfileSelect(theProfile);
    }
  }

  return (
    <div className="ProfilesList">
      {props.data.map((profile, i) => {
        return (
          <ProfileCard
            data={profile}
            key={i}
            onClick={(theProfile) => onCardClick(theProfile)}
          />
        );
      })}
    </div>
  );
}

export default ProfilesList;
