import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import DICTIONARY from './dictionary.json';
import Locale from '../../../../services/locale';
import FormLine from '../../../../components/Form/FormLine';
import FormColumn from '../../../../components/Form/FormColumn';
import InputTextArea from '../../../../components/Form/InputTextArea';
import InputSelect from '../../../../components/Form/InputSelect';
import Button from '../../../../components/Button/Button';
import { stripTags } from '../../../../services/data';

function InvitationPostForm(props) {
  const EMPTY_POST = {
    content: '',
    level: 'info',
  };
  const SAY = new Locale([DICTIONARY]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [draft, setDraft] = useState(EMPTY_POST);
  const levelOptions = [
    { label: SAY.postForm.levels.info, value: 'info' },
    { label: SAY.postForm.levels.warning, value: 'warning' },
    { label: SAY.postForm.levels.critical, value: 'critical' },
  ];

  useEffect(() => {
    setDraft(props.data);
    // eslint-disable-next-line
  }, [props.data]);

  function onSubmit(data, { resetForm }) {
    if (data.content.length > 0 && props.onSubmitPost) {
      data.content = stripTags(data.content);
      props.onSubmitPost(data);
    }
    setCanSubmit(false);
    resetForm({});
  }

  function renderFormHtml() {
    return (
      <Formik initialValues={draft} onSubmit={onSubmit}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <h3>{SAY.addNewEntry}</h3>
            <FormLine>
              <FormColumn>
                <InputTextArea
                  autoComplete="off"
                  formik={formik}
                  name="content"
                  onChange={(evt) => {
                    formik.handleChange(evt);
                    setCanSubmit(evt.target.value.length >= 3);
                  }}
                  label={SAY.postForm.content}
                />
              </FormColumn>
            </FormLine>
            <FormLine>
              <FormColumn style={{ width: '100%', textAlign: 'right' }}>
                <InputSelect
                  name="level"
                  label={SAY.postForm.level}
                  options={levelOptions}
                />
              </FormColumn>
            </FormLine>
            <FormLine>
              <FormColumn style={{ width: '100%', textAlign: 'right' }}>
                <Button type="submit" disabled={!canSubmit}>
                  {SAY.postForm.post}
                </Button>
              </FormColumn>
            </FormLine>
          </form>
        )}
      </Formik>
    );
  }

  return <div className="InvitatinPostForm">{renderFormHtml()}</div>;
}

InvitationPostForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmitPost: PropTypes.func.isRequired,
};

export default InvitationPostForm;
