import getEnv from '../env';

export const avatarUrl = (name) => {
  const path = getEnv('ASSETS_ROOT');
  return `${path}/avatars/${name}.svg`;
};

export const base64Decode = (src) => {
  return atob(src);
};

export const base64Encode = (src) => {
  return btoa(src);
};

export const invitationPreviewUrl = (profile, data) => {
  const {
    date,
    end_tm,
    event_type,
    intro,
    location,
    signature,
    notes,
    start_tm,
    theme_uuid,
    title,
  } = data;
  const invitationData = {
    date,
    endTm: end_tm,
    eventType: event_type,
    intro,
    location,
    signature,
    notes,
    startTm: start_tm,
    themeUuid: theme_uuid,
    title,
  };
  invitationData.personaUuid = profile.uuid;
  const lang = profile.language;
  const q = base64Encode(encodeURI(JSON.stringify(invitationData)));
  const url = getEnv('INVITATION_PREVIEW_ROOT') + `/${lang}/${q}`;
  return url;
};

export const invitationAnonUrl = (uuid) => {
  const path = getEnv('INVITATIONS_ROOT');
  return `${path}/${uuid}`;
};

export const invitationUrl = (invitationUuid, contactUuid) => {
  const token = base64Encode(`${invitationUuid}.${contactUuid}`);
  return getEnv('INVITATIONS_ROOT') + `/${token}`;
};

export const invitationAnonymousUrl = (uuid) => {
  const token = base64Encode(uuid);
  return getEnv('INVITATIONS_ROOT') + `/${token}`;
};


export const themePreviewUrl = (lang, themeUuid) => {
  const path = getEnv('INVITATION_PREVIEW_ROOT');
  return `${path}/${lang}/${themeUuid}`;
};

// DEPRECATE begin
// export function useQueryParams(location) {
//   const params = new URLSearchParams(location.search);
//   return new Proxy(params, {
//     get(target, prop) {
//       return target.get(prop);
//     },
//   });
// }
// DEPRECATE end
