import './Sidebar.scss';
import { useEffect } from 'react';
import MainMenu from '../Menu/MainMenu';
import IconButton from '../Button/IconButton';
import ProfilesQuickAccess from './components/ProfilesQuickAccess/ProfilesQuickAccess';

function Sidebar(props) {
  useEffect(() => {
    if (props.isOpen) {
      document.body.classList.add('sidebarOpen');
    } else {
      document.body.classList.remove('sidebarOpen');
    }
  });

  const mainMenu = () => {
    return props.withMainMenu ? <MainMenu className="sidebar" /> : null;
  };

  function onClose() {
    if (props.onClose) {
      props.onClose();
    }
  }

  const profiles = () => {
    return (
      <div className="sidebarProfiles">
        <div className="closeButton">
          <IconButton icon="close" onClick={() => onClose()} />
        </div>
        <div className="profilesQuickAccessContainer">
          <ProfilesQuickAccess />
        </div>
      </div>
    );
  };

  const isOpenClass = props.isOpen ? 'open' : '';
  const className = `Sidebar ${isOpenClass}`;
  const overlayClassName = `sidebarOverlay ${isOpenClass}`;
  return (
    <>
      <div className={overlayClassName}></div>
      <div className={className}>
        {mainMenu()}
        {profiles()}
      </div>
    </>
  );
}

export default Sidebar;
