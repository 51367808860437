import generateUuid from '../helpers/uuid';
import { getActiveProfile, updateProfile } from '../helpers/profiles';
import { apiPost } from '../api/ajax';
import { makeApiUrl } from '../api';
import { syncState } from '../api/state';

export const INVITATION_DRAFT = {
  uuid: '',
  event_type: '',
  title: '',
  date: '',
  start_tm: '',
  end_tm: '',
  intro: '',
  location: '',
  notes: '',
  signature: '',
  safeword: '',
  persona_uuid: '',
  theme_uuid: '',
  service: '',
  published: 0,
};

export const addInvitation = (data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.invitations) {
        theProfile = Object.assign({}, theProfile, { invitations: [] });
      }
      theProfile.invitations.push(data);
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        return resolve(data);
      });
    });
  });
};

export const canDelete = (data) => {
  const r = !data.published;
  return r;
};

export const createInvitationDraftWithTheme = (theme) => {
  return new Promise((resolve) => {
    getInvitationDraft().then((draft) => {
      if (!draft) {
        draft = Object.assign({}, INVITATION_DRAFT, {
          theme_uuid: theme.uuid,
          service: theme.service,
        });
      } else {
        draft.theme_uuid = theme.uuid;
      }
      setInvitationDraft(draft).then((draft) => {
        resolve(draft);
      });
    });
  });
};

export const deleteInvitationByUuid = (uuid) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.invitations) {
        theProfile = Object.assign({}, theProfile, { invitations: [] });
      }
      const found = theProfile.invitations.findIndex((item) => {
        return item.uuid === uuid;
      });
      if (found >= 0) {
        theProfile.invitations.splice(found, 1);
      }
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        return resolve(updatedProfile.invitations);
      });
    });
  });
};

export const getInvitationByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    if (!uuid || uuid === 'new') {
      const draft = getInvitationDraftOrFallback();
      resolve(draft);
    } else {
      getActiveProfile().then((theProfile) => {
        if (!theProfile.invitations) {
          theProfile = Object.assign({}, theProfile, { invitations: [] });
        }
        const found = theProfile.invitations.findIndex((item) => {
          return item.uuid === uuid;
        });
        if (found >= 0) {
          return resolve(theProfile.invitations[found]);
        } else {
          return reject('Invitation not found');
        }
      });
    }
  });
};

export const getInvitationDraft = () => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.drafts || !theProfile.drafts.invitation) {
        return resolve(null);
      }
      return resolve(theProfile.drafts.invitation || null);
    });
  });
};

export const getInvitationDraftOrFallback = () => {
  return new Promise((resolve) => {
    getInvitationDraft().then((theDraft) => {
      resolve(theDraft || Object.assign({}, {}, INVITATION_DRAFT));
      return;
    });
  });
};

export const getInvitations = () => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      return resolve(theProfile.invitations || []);
    });
  });
};

export const hydratedOutboxEntry = (name) => {
  return {
    uuid: generateUuid(),
    name,
  };
};

export const publishInvitationByUuid = (uuid, published = true) => {
  return new Promise((resolve) => {
    syncState().then(() => {
      const payload = {
        published,
      };
      apiPost({
        url: makeApiUrl(`invitations/${uuid}`),
        payload,
      }).then(() => {
        updateInvitation(uuid, { published }).then((response) => {
          const modifiedInvitation = response;
          return resolve(modifiedInvitation);
        });
      });
    });
  });
};

export const resetInvitationDraft = () => {
  return setInvitationDraft(null);
};

export const setInvitationDraft = (data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      theProfile.drafts = Object.assign({}, theProfile.drafts, {
        invitation: data,
      });
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        return resolve(updatedProfile.drafts.invitation);
      });
    });
  });
};

export const unpublishInvitationByUuid = (uuid) => {
  return publishInvitationByUuid(uuid, false);
};

export const updateInvitation = (uuid, data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.invitations) {
        theProfile = Object.assign({}, theProfile, { invitations: [] });
      }
      const found = theProfile.invitations.findIndex((item) => {
        return item.uuid === uuid;
      });
      if (found >= 0) {
        theProfile.invitations[found] = Object.assign(
          {},
          theProfile.invitations[found],
          data
        );
      }
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        return resolve(updatedProfile.invitations[found]);
      });
    });
  });
};

export const updateOutboxEntryName = (
  invitationUuid,
  outboxEntryUuid,
  newName
) => {
  return new Promise((resolve) => {
    getInvitationByUuid(invitationUuid).then((invitation) => {
      const outbox = invitation.outbox;
      const found = outbox.findIndex((item) => {
        return item.uuid === outboxEntryUuid;
      });
      if (found >= 0) {
        outbox[found] = Object.assign({}, outbox[found], { name: newName });
        invitation.outbox = outbox;
        updateInvitation(invitationUuid, invitation).then(() => {
          return resolve(invitation);
        });
      }
    });
  });
};
