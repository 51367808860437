import './AvatarCard.scss';
import Card from '../../../../../../components/Card/Card';
import Avatar from '../../../../../../components/Avatar/Avatar';

function AvatarCard(props) {
  return (
    <Card className="AvatarCard" onClick={props.onClick}>
      <Avatar name={props.data.name} />
    </Card>
  );
}

export default AvatarCard;
