import './ButtonsBar.scss';

function ButtonsBar(props) {
  const staticBar = props.static ? ' static' : '';
  const fixed = props.fixed ? ' fixed' : '';
  const fullWidth = props.fullWidth ? ' fullWidth' : '';
  const className = `ButtonsBar${fixed}${fullWidth}`;
  const decoClassName = `deco${fixed}${staticBar}`;
  return (
    <div className={className}>
      <div className={decoClassName}>
        <div className="container">
          {!!props.left && <div className="left">{props.left}</div>}
          {!!props.main && <div className="center">{props.main}</div>}
          {!!props.right && <div className="right">{props.right}</div>}
        </div>
      </div>
    </div>
  );
}

export default ButtonsBar;
