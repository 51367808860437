import './InputSelect.scss';
import React, { useRef } from 'react';
import { useField } from 'formik';
import TipText from '../OnBoard/TipText';

// https://formik.org/docs/api/useField
function InputSelect(props) {
  const [field, meta] = useField(props);
  const inputElement = useRef(null);

  const disabledClass = props.disabled ? 'disabled' : '';
  const className = `inputContainer ${disabledClass} ${props.className || ''}`;
  const inputClassName = `input`;
  const { options, tipText, ...rest } = props;

  const tipTextHtml = () => {
    if (!tipText) {
      return null;
    } else {
      return <TipText>{tipText}</TipText>;
    }
  };

  function renderOptions() {
    if (!options) {
      return null;
    }
    return options.map((o) => {
      return (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      );
    });
  }

  return (
    <div className="InputSelect">
      <div
        className={className}
        onClick={(_e) => {
          inputElement.current.focus();
        }}
      >
        <select
          className={inputClassName}
          ref={inputElement}
          {...field}
          {...rest}
        >
          {renderOptions()}
        </select>
        <label>{props.label || props.name}</label>
      </div>
      {tipTextHtml()}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default InputSelect;
