import './Loading.scss';

function Loading(props) {
  const size = { width: 500, height: 500 };
  if (props.small) {
    size.width = 42;
    size.height = 42;
  }
  if (props.size) {
    size.width = props.size;
    size.height = props.size;
  }
  const extraClasses =
    (props.small ? 'small ' : '') + (props.inline ? 'inline ' : '');
  const className = `Loading ${extraClasses}`;
  return (
    <div className={className} style={props.style || null}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size.width}
        height={size.height}
        viewBox="0 0 132.29166 132.29167"
        version="1.1"
        id="svg13762"
      >
        <g
          id="mascot-progress-spinner"
          fillOpacity="1"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeDasharray="none"
          strokeDashoffset="0"
          strokeOpacity="1"
        >
          <g
            id="face"
            transform="matrix(.26458 0 0 .26458 -.00002422 116.41669)"
            display="inline"
          >
            <circle
              r="137.4071"
              cy="-190.00009"
              cx="250.00009"
              id="path6965"
              display="inline"
              opacity="1"
              fill="#559aff"
              strokeWidth="5"
            />
            <path
              id="path6995"
              d="M197.89788-252.0103c-11.45935-.26666-23.52188 5.83001-30.95218 18.66668-.32367.63333.41242.44167.80367 0 16.56988-18.68418 41.73373-18.98168 56.00334 0 .81584.97417 1.27538.6 1.07465 0-5.13102-12.05167-15.73036-18.40668-26.92948-18.66668zm108.30374 0c-11.4596-.26666-23.52677 5.83001-30.95706 18.66668-.32367.63333.41733.44167.80833 0 16.57012-18.68418 41.73372-18.98168 56.00352 0 .81584.97417 1.2705.6 1.06984 0-5.13126-12.05167-15.72549-18.40668-26.92463-18.66668z"
              display="inline"
              opacity="1"
              fill="#000"
              strokeWidth="4.87809"
            />
            <g
              transform="matrix(8.33334 0 0 8.33334 -.61485433 -440.00023)"
              id="g7143"
              display="inline"
              strokeWidth=".58537"
            >
              <path
                id="path7141"
                d="M36.179068 32.08068c-.03981-.01-.105952.015-.190538.087-2.958665 2.8024-8.175919 2.7584-11.611543 0-.0302-.024-.06825-.041-.103406-.051-.03705-.021-.07742-.035-.120235-.032-.131562.01-.233738.1184-.227153.2516l.04592.8899c.09723 1.9039.365241 3.4239.837049 4.639v0c.343788.873.795752 1.6552 1.370322 2.2354.612941.6188 1.40641 1.0656 2.211498 1.2384.738824.1587 1.730675.1217 2.679677-.04.328246-.057.652541-.1309.960797-.2225l.447866-.1337c.172128-.052.227358-.2704.100508-.399-1.68086-1.4592-2.713511-3.6368-2.027926-5.6202.02517-.0002.05087 0 .0761 0 2.322016-.038 4.5197-.9759 5.58358-2.7552.02078-.044.0067-.081-.03307-.088z"
                opacity="1"
                fill="#000"
              />
              <path
                id="path6997"
                d="M26.461372 38.77138c-.368959 0-.746376.054-1.119198.1699.248694.4359.52894.8324.853059 1.1597.612971.6188 1.406908 1.0662 2.211967 1.2391.414237.089.910396.1113 1.431551.09-.264763-1.5822-1.741826-2.6566-3.377379-2.6583z"
                opacity="1"
                fill="red"
              />
            </g>
          </g>
          <path
            d="M55.440325 6.8124464c-1.16128 2.034458-.50858 4.6276356 1.43502 6.1267656-1.92863 1.518444-2.55558 4.117443-1.3743 6.140445 1.1747 2.011627 3.89999 3.004076 6.03668 1.911614.35182 2.428079 2.28873 4.269047 4.63029 4.257405 2.35275 0 4.59579-1.91267 4.67691-4.344188 2.26885.885558 4.80925.127 5.96336-1.89574 1.14842-2.011889.65604-4.847426-1.33054-6.161346 1.97361-1.333765 2.4378-4.1740646 1.26955-6.1747346-1.17514-2.012315-3.72639-2.745711-5.98789-1.834277-.16467-2.379078-2.39434-4.20440897-4.7131-4.19300597-2.30936.01323-4.20587 1.82136297-4.57216 4.20353597-2.14992-1.084021-4.87616-.06429-6.03382 1.963526z"
            id="floripon"
            display="inline"
            opacity="1"
            fill="#ffba44"
            strokeWidth="1.29066"
          />
        </g>
      </svg>
    </div>
  );
}

export default Loading;
