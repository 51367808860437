import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { getDeviceId } from './services/data/storage/device-info';
import { calculateRediretionTarget } from './services/routes';

/** Route classes */
import ROUTES from './services/routes';
import HomePage from './pages/Home/HomePage';
import ProfilesPage from './pages/Profiles/ProfilesPage';
import ProfileEditorPage from './pages/Profiles/ProfileEditorPage';
import SettingsPage from './pages/Settings/SettingsPage';
import ThemesPage from './pages/Themes/ThemesPage';
import InvitationsPage from './pages/Invitations/InvitationsPage';
import InvitationEditorPage from './pages/Invitations/InvitationEditorPage';
import InvitationOutboxPage from './pages/Invitations/InvitationOutboxPage';
import InvitationGuestsPage from './pages/Invitations/InvitationGuestsPage';
import InvitationPostsPage from './pages/Invitations/InvitationPostsPage';
import WishCategoriesPage from './pages/WishCategories/WishCategoriesPage';
import WishCategoryEditorPage from './pages/WishCategories/WishCategoryEditorPage';
import WishListPage from './pages/WishLists/WishListPage';
import WishItemEditorPage from './pages/WishLists/WishItemEditorPage';
/** end Route classes */

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirect = () => {
      calculateRediretionTarget(true, location).then((target) => {
        if (target) {
          navigate(target);
        }
      });
    };

    getDeviceId()
      .then(() => {
        redirect();
      })
      .catch(() => {
        redirect();
      });
  }, [location, navigate]);

  return (
    <>
      <Routes>
        <Route exact path={ROUTES.home} element={<HomePage />} />
        <Route exact path={ROUTES.invitations} element={<InvitationsPage />} />
        <Route
          exact
          path={ROUTES.invitationGuests}
          element={<InvitationGuestsPage />}
        />
        <Route
          exact
          path={ROUTES.invitationPosts}
          element={<InvitationPostsPage />}
        />
        <Route exact path={ROUTES.wishItems} element={<WishListPage />} />
        <Route
          exact
          path={ROUTES.wishItemEditor}
          element={<WishItemEditorPage />}
        />
        <Route
          exact
          path={ROUTES.wishCategories}
          element={<WishCategoriesPage />}
        />
        <Route
          exact
          path={ROUTES.wishCategoryEditor}
          element={<WishCategoryEditorPage />}
        />
        <Route
          exact
          path={ROUTES.invitationEditor}
          element={<InvitationEditorPage />}
        />
        <Route
          exact
          path={ROUTES.invitationOutbox}
          element={<InvitationOutboxPage />}
        />
        <Route exact path={ROUTES.profiles} element={<ProfilesPage />} />
        <Route
          exact
          path={ROUTES.profileEditor}
          element={<ProfileEditorPage />}
        />
        <Route exact path={ROUTES.settings} element={<SettingsPage />} />
        <Route exact path={ROUTES.themes} element={<ThemesPage />} />
      </Routes>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
