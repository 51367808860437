import './Card.scss';

function Card(props) {
  function onClick() {
    if (props.onClick) {
      props.onClick();
    }
  }

  const className = `Card ${props.className || ''}`;
  return (
    <div className={className} onClick={() => onClick()}>
      {props.children}
    </div>
  );
}

export default Card;
