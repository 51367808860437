import LocalizedStrings from 'react-localization';
import LANGUAGES from './languages.json';
import moment from 'moment';
import merge from 'lodash/merge';
import { getActiveLangauge } from '../data/local-storage';

export const LOCALIZED_MOMENT = moment;

export const MOMENT_LOCALES = {
  en: {
    months:
      'January_February_Mars_April_May_June_July_August_September_October_November_December'.split(
        '_'
      ),
    monthsShort:
      'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec.'.split('_'),
    monthsParseExact: true,
    weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thirsday_Friday_Saturday'.split('_'),
    weekdaysShort: 'Sun_Mon_Tue_Wed_Thi_Fri_Sat.'.split('_'),
    weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH[h]mm',
      LTS: 'HH[h]mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      nextWeek: 'dddd [at] LT',
      lastDay: '[Yesterdaqy at] LT',
      lastWeek: '[Last] dddd [at] LT',
      sameElse: 'L',
    },
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'one hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(st)/,
    ordinal: function (number) {
      let suffix = 'th';
      if (number === 1) suffix = 'st';
      else if (number === 2) suffix = 'nd';
      else if (number === 3) suffix = 'rd';
      return number + suffix;
    },
    meridiemParse: /AM|PM/,
    isPM: function (input) {
      return input.charAt(0) === 'A';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? 'AM' : 'PM';
    },
    week: {
      dow: 0, // Sunday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  },
  fr: {
    months:
      'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
        '_'
      ),
    monthsShort:
      'janv_févr_mars_avr_mai_juin_juil_août_sept_oct_nov_déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim_lun_mar_mer_jeu_ven_sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH[h]mm',
      LTS: 'HH[h]mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
      sameDay: '[Aujourd’hui à] LT',
      nextDay: '[Demain à] LT',
      nextWeek: 'dddd [à] LT',
      lastDay: '[Hier à] LT',
      lastWeek: 'dddd [dernier à] LT',
      sameElse: 'L',
    },
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
      return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
      return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? 'PD' : 'MD';
    },
    week: {
      dow: 0, // Sunday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  },
  es: {
    months:
      'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Setiembre_Octubre_Noviembre_Diciembre'.split(
        '_'
      ),
    monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Set_Oct_Nov_Dic'.split('_'),
    monthsParseExact: true,
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sáb'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH[h]mm',
      LTS: 'HH[h]mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
      sameDay: '[Hoy a] LT',
      nextDay: '[Mañana a] LT',
      nextWeek: 'dddd [a] LT',
      lastDay: '[Ayer a] LT',
      lastWeek: 'dddd [pasado a] LT',
      sameElse: 'L',
    },
    relativeTime: {
      future: 'en %s',
      past: 'hace %s',
      s: 'algunos segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un día',
      dd: '%d dias',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(ro|do|ro|to|mo|vo|no)/,
    ordinal: function (number) {
      let ord;
      if (number === 1) {
        ord = 'ro';
      }
      if (number === 2) {
        ord = 'do';
      }
      if (number === 3) {
        ord = 'ro';
      }
      if ([4, 5, 6].find(number)) {
        ord = 'to';
      }
      if (number === 7 || number === 10) {
        ord = 'mo';
      }
      if (number === 8) {
        ord = 'vo';
      }
      if (number === 9) {
        ord = 'no';
      }
      return number + ord;
    },
    meridiemParse: /AM|PM/,
    isPM: function (input) {
      return input.charAt(0) === 'P';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? 'AM' : 'PM';
    },
    week: {
      dow: 0, // Sunday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  },
};

export const navigatorLanguage = (defLang) => {
  const navl = navigator.language || defLang;
  return navl.substr(0, 2);
};

export const getAppliedLanguage = () => {
  const profileLanguage = getActiveLangauge();
  const calculatedLanguage = profileLanguage
    ? profileLanguage
    : navigatorLanguage(LANGUAGES[0].value);
  return calculatedLanguage;
};

export default function Locale(translations, forceLanguage = null) {
  if (Array.isArray(translations)) {
    const merged = {};
    if (translations && translations.length) {
      for (let set of translations) {
        for (let lang in set) {
          merged[lang] = merged[lang] || {};
          merged[lang] = merge(merged[lang], set[lang]);
        }
      }
    }
    translations = merged;
  }

  const ls = new LocalizedStrings(translations);

  const calculatedLanguage = getAppliedLanguage();
  const language = forceLanguage || calculatedLanguage;

  ls.setLanguage(language);
  LOCALIZED_MOMENT.locale(language, MOMENT_LOCALES[language]);

  return ls;
}
