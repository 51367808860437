import OnBoard from '../../../OnBoard/OnBoard';
import DICTIONARY from './dictionary.json';
import Locale from '../../../../services/locale';
import mdToHtml from '../../../../services/markdown';

function NoThemesFound() {
  const SAY = new Locale([DICTIONARY]);
  const asHtml = mdToHtml(SAY.noThemesFound);
  return (
    <OnBoard className="NoThemesFound">
      <div dangerouslySetInnerHTML={{__html: asHtml}} />
    </OnBoard>
  );
}

export default NoThemesFound;
