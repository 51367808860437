import { makeApiUrl } from './';
import { base64Encode } from '../base64';
import { apiGet } from './ajax';

export const fetchProductInfo = (url) => {
  return new Promise((resolve, reject) => {
    const encodeUrl = (url) => {
      return base64Encode(url);
    };

    const endpoint =
      makeApiUrl('parsed-wished-product') + '?token=' + encodeUrl(url);
    apiGet({
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          return reject(response.json);
        } else {
          return resolve(response.json);
        }
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
