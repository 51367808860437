import mdToHtml from '../../services/markdown';
import './Explain.scss';

function Explain(props) {
  function render() {
    const className = `Explain ${props.className || ''}`;
    if (props.asHtml) {
      const html = mdToHtml(props.children);
      return (
        <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
      );
    } else {
      return <div className={className}>{props.children}</div>;
    }
  }

  return render();
}

export default Explain;
