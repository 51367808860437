import './ImageZoomModal.scss';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Icon from '../Icon/Icon';

function ImageZoomModal(props) {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      style={{
        content: {
          inset: '8px',
          padding: '4px',
          maxWidth: props.maxWidth || '980px',
          margin: '0 auto',
        },
      }}
    >
      <div className="titleBar">
        <Icon clickable name="close" onClick={props.onClose} />
        <h2>{props.alt}</h2>
      </div>
      <img src={props.src} alt={props.alt} />
    </Modal>
  );
}

ImageZoomModal.propTypes = {
  alt: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};

export default ImageZoomModal;
