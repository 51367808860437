import Toolbar from '../../components/Toolbar/Toolbar';
import React from 'react';
import Loading from '../../components/Loading/Loading';
import NetworkError from '../../components/OnBoard/NetworkError';
import ThemesList from '../../components/ThemesList/ThemesList';
import { useGetThemes } from '../../services/data/hooks/themes';
import { createInvitationDraftWithTheme } from '../../services/data/helpers/invitations';
import { useNavigate } from 'react-router-dom';
import ROUTES, { makePath } from '../../services/routes/';

function ThemesPage() {
  const [data, ajaxLoading, ajaxError] = useGetThemes();
  const navigate = useNavigate();

  function getContent() {
    if (!data || ajaxLoading()) {
      return <Loading />;
    } else if (ajaxError()) {
      return <NetworkError />;
    } else {
      return (
        <ThemesList
          data={data.data}
          metadata={data.metadata}
          onThemeSelect={(theme) => onThemeSelect(theme)}
        />
      );
    }
  }

  function onThemeSelect(theme) {
    createInvitationDraftWithTheme(theme).then(() => {
      navigate(makePath(ROUTES.invitationEditor, { uuid: 'new' }));
    });
  }

  return (
    <div className="pageWithMenuLayout">
      <Toolbar goBack title="Themes" enableMenu withMenu withSidebar />
      <div className="ThemesPage page">{getContent()}</div>
    </div>
  );
}

export default ThemesPage;
