import './WishItemForm.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../../../services/dictionary/common-dictionary.json';
import Locale from '../../../../services/locale';
import ButtonsBar from '../../../../components/ButtonsBar/ButtonsBar';
import Button from '../../../../components/Button/Button';
import OutlineButton from '../../../../components/Button/OutlineButton';
import InputImageUrl from '../../../../components/Form/InputImageUrl';
import InputTextArea from '../../../../components/Form/InputTextArea';
import InputSelect from '../../../../components/Form/InputSelect';
import FormLine from '../../../../components/Form/FormLine';
import WishItemSearchInput from '../WishItemSearchInput/WishItemSearchInput';
import ROUTES from '../../../../services/routes';
import { useGetWishCategoriesList } from '../../../../services/data/hooks/wish-categories';
import { getDefaultWishCategories } from '../../../../services/data/helpers/wish-categories';

function WishItemForm(props) {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [draft, setDraft] = useState(props.data);
  const [categories, loadingCategories] = useGetWishCategoriesList();
  const [categoriesOptions, setCategoriesOptions] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.onChange) {
      props.onChange(draft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  useEffect(() => {
    if (categories && categories.length) {
      const options = categories.map((item) => {
        return {
          value: item.uuid,
          label: item.name,
        };
      });
      const extra = getDefaultWishCategories();
      setCategoriesOptions([...options, ...extra]);
    }
  }, [categories]);

  function canDelete() {
    // TO-DO: We can delete if the item doesn't have reservations.
    return true;
  }

  function navigateToCategoriesManager() {
    navigate(ROUTES.wishCategories);
  }

  function productInfoReceived(data) {
    const cleanData = {
      name: data.name,
      url: data.url,
      image_url: data.images[0],
    };
    setDraft(cleanData);
  }

  function onDelete() {
    if (props.onDelete) {
      props.onDelete(props.data);
    }
  }

  function onSubmit(data) {
    if (props.onSubmit) {
      props.onSubmit(data);
    }
  }

  function onUrlChange(evt) {
    const newDraft = Object.assign({}, draft);
    setDraft(
      Object.assign({}, newDraft, { [evt.target.name]: evt.target.value })
    );
  }

  function resetForm(setFieldValue) {
    const newDraft = Object.assign({}, draft);
    for (let key of Object.keys(props.data)) {
      setFieldValue(key, '');
      newDraft[key] = '';
    }
    setDraft(newDraft);
  }

  function renderCategoriesControls() {
    if (loadingCategories) {
      return '...';
    }
    return (
      <FormLine>
        <InputSelect
          id="category_uuid"
          label={SAY.label.category}
          name="category_uuid"
          options={categoriesOptions}
        />
        <div style={{ textAlign: 'right' }}>
          <OutlineButton
            href={ROUTES.wishCategories}
            onClick={navigateToCategoriesManager}
            trailingIcon="chevron-right"
            theme="primary"
          >
            {SAY.manageCategories}
          </OutlineButton>
        </div>
      </FormLine>
    );
  }

  function renderDeleteButton() {
    if (
      canDelete() &&
      props.data &&
      props.data.uuid &&
      props.data.uuid.length > 0
    ) {
      return (
        <OutlineButton theme="danger" onClick={onDelete}>
          {SAY.delete}
        </OutlineButton>
      );
    }
  }

  return (
    <div className="WishItemForm fields">
      <Formik initialValues={draft} enableReinitialize onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="fields">
              <FormLine>
                <Field
                  component={WishItemSearchInput}
                  label={SAY.label.productUrl}
                  name="url"
                  onInputChange={onUrlChange}
                  onProductInfoReceived={productInfoReceived}
                  onReset={() => resetForm(setFieldValue)}
                />
              </FormLine>
              <FormLine>
                <InputTextArea label={SAY.label.name} name="name" />
              </FormLine>
              <FormLine>
                <Field
                  component={InputImageUrl}
                  label={SAY.label.imageUrl}
                  name="image_url"
                />
              </FormLine>
              {renderCategoriesControls()}
            </div>
            <ButtonsBar
              left={renderDeleteButton()}
              right={<Button type="submit">{SAY.save}</Button>}
              fixed
            ></ButtonsBar>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default WishItemForm;
