import ROUTES from '../../services/routes';
import DICTIONARY from './dictionary.json';
import Locale from '../../services/locale';

export const buildMenuOptions = (activeProfile) => {
  const language = activeProfile ? activeProfile.language : null;
  const SAY = new Locale(DICTIONARY, language || null);
  return [
    {
      href: ROUTES.home,
      name: SAY.home,
    },
    {
      href: ROUTES.profiles,
      name: SAY.profiles,
    },
    {
      href: ROUTES.themes,
      name: SAY.themes,
    },
    {
      href: ROUTES.invitations,
      name: SAY.invitations,
    },
    {
      href: ROUTES.contacts,
      name: SAY.contacts,
    },
    {
      href: ROUTES.wishItems,
      name: SAY.wishList,
    },
    {
      href: ROUTES.wishCategories,
      name: SAY.wishCategories,
    },
    {
      href: ROUTES.account,
      name: SAY.account,
    },
    {
      href: ROUTES.settings,
      name: SAY.settings,
    },
  ];
};