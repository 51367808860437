import { getActiveProfile, updateProfile } from '../helpers/profiles';

export const WISH_ITEM = {
  uuid: '',
  url: '',
  name: '',
  image_url: '',
  category_uuid: '',
};

export const addWishItem = (data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.wishItems) {
        theProfile = Object.assign({}, theProfile, { wishItems: [] });
      }
      theProfile.wishItems.push(data);
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        resolve(updatedProfile);
        return;
      });
    });
  });
};

export const createWishItemDraft = () => {
  const draft = Object.assign({}, WISH_ITEM);
  setWishItemDraft(draft);
};

export const deleteWishItemByUuid = (uuid) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.wishItems) {
        resolve([]);
      }
      const found = theProfile.wishItems.findIndex((item) => {
        return (item.uuid === uuid);
      });
      if (found >= 0) {
        theProfile.wishItems.splice(found, 1);
      }
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        resolve(updatedProfile.wishItems);
        return;
      });
    });
  });
};

export const getWishItemByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    getActiveProfile().then((theProfile) => {
      if (uuid === 'new') {
        return resolve(WISH_ITEM);
      }
      if (!theProfile.wishItems) {
        return reject(null);
      }
      const found = theProfile.wishItems.findIndex((item) => {
        return (item.uuid === uuid);
      });
      return found >= 0 ? resolve(theProfile.wishItems[found]) : reject();
    });
  });
};

export const getWishItemDraft = () => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.drafts || !theProfile.drafts.wishItem) {
        resolve(null);
        return;
      }
      resolve(theProfile.drafts.wishItem || null);
      return;
    });
  });
};

export const getWishItemDraftOrFallback = () => {
  return new Promise((resolve) => {
    getWishItemDraft().then((theDraft) => {
      resolve(theDraft || Object.assign({}, {}, WISH_ITEM));
      return;
    });
  });
};

export const getWishItems = () => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      return resolve(theProfile.wishItems || []);
    });
  });
};

export const resetWishItemDraft = () => {
  setWishItemDraft(null);
};

export const setWishItemDraft = (data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      theProfile.drafts = Object.assign({}, theProfile.drafts, {
        wishItem: data,
      });
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        resolve(updatedProfile.drafts.wishItem);
        return;
      });
    });
  });
};

export const updateWishItem = (uuid, data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.wishItems) {
        return resolve([]);
      }
      const found = theProfile.wishItems.findIndex((item) => {
        return (item.uuid === uuid);
      });
      if (found >= 0) {
        theProfile.wishItems[found] = Object.assign(
          {},
          theProfile.wishItems[found],
          data
        );
      }
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        resolve(updatedProfile.wishItems);
        return;
      });
    });
  });
};
