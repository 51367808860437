export const SERVICES = [
  {
    value: 'all',
    label: {
      en: 'All services',
      fr: 'Tous les services',
      es: 'Todos los servicios',
    },
  },
  {
    value: 'event',
    label: {
      en: 'Event',
      fr: 'Événement',
      es: 'Evento',
    },
  },
  {
    value: 'wishlist',
    label: {
      en: 'Wish list',
      fr: 'Liste de souhaits',
      es: 'Lista de deseos',
    },
  },
];

export const getAllServiceKeys = () => {
    const keys = [];
    for (let s of SERVICES) {
        if (s.value !== 'all') {
            keys.push(s.value);
        }
    }
    return keys;
};