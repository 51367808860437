import './InvitationPostsPage.scss';
import { useState, useEffect } from 'react';
// import _ from 'lodash';
import { useParams } from 'react-router-dom';
import Toolbar from '../../components/Toolbar/Toolbar';
// import Loading from '../../components/Loading/Loading';
// import Icon from '../../components/Icon/Icon';
// import ROUTES from '../../services/routes/';
import DICTIONARY from './dictionary.json';
import Locale from '../../services/locale';
// import { getInvitationByUuid } from '../../services/data/helpers/invitations';
import InvitationPosts from './components/InvitationPosts/InvitationPosts';
import { useGetInvitationByUuid } from '../../services/data/hooks/invitations';
import InvitationPostForm from './components/InvitationPosts/InvitationPostForm';
import {
  getInvitationByUuid,
  updateInvitation,
} from '../../services/data/helpers/invitations';
import generateUuid from '../../services/data/uuid';
import { dateToTimestamp } from '../../services/locale/date-time';

function InvitationPostsPage() {
  const SAY = new Locale([DICTIONARY]);
  const { uuid } = useParams();
  const [theInvitation, loadingInvitation] = useGetInvitationByUuid(uuid);
  const [invitation, setInvitation] = useState();

  useEffect(() => {
    if (!loadingInvitation && theInvitation) {
      setInvitation(theInvitation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theInvitation]);

  function onDeletePost(postUuid) {
    let posts = theInvitation.posts || [];
    const index = theInvitation.posts.findIndex((item) => {
      return item.uuid === postUuid;
    });
    if (index >= 0) {
      posts.splice(index, 1);
      updateInvitation(theInvitation.uuid, { posts }).then(() => {
        getInvitationByUuid(theInvitation.uuid).then((result) => {
          setInvitation(result);
        });
      });
    }
  }

  function onSubmitPost(data) {
    const posts = theInvitation.posts || [];
    const hydratedData = Object.assign({}, data, {
      uuid: generateUuid(),
      timestamp: dateToTimestamp(new Date()),
    });
    posts.push(hydratedData);
    updateInvitation(theInvitation.uuid, { posts: posts }).then(() => {
      getInvitationByUuid(theInvitation.uuid).then((result) => {
        setInvitation(result);
      });
    });
  }

  function renderHtml() {
    const pageTitle = invitation ? invitation.title : '...';
    const subTitle = 'Posts';
    return (
      <div className="InvitationPostsPage page">
        <Toolbar goBack title={pageTitle} subTitle={subTitle} />
        <div className="content">
          <h3>{SAY.addNewPost}</h3>
          <InvitationPostForm data={{}} onSubmitPost={onSubmitPost} />
          <InvitationPosts
            invitation={invitation || {}}
            onDeletePost={onDeletePost}
          />
        </div>
      </div>
    );
  }

  return renderHtml();
}

export default InvitationPostsPage;
