import Locale from '../../locale';
import DICTIONARY from './dictionary.json';

import { getActiveProfile, updateProfile } from '../helpers/profiles';

export const WISH_CATEGORY = {
  uuid: '',
  name: '',
  description: '',
};

export const addWishCategory = (data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.wishCategories) {
        theProfile = Object.assign({}, theProfile, { wishCategories: [] });
      }
      theProfile.wishCategories.push(data);
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        resolve(updatedProfile);
        return;
      });
    });
  });
};

export const deleteWishCategoryByUuid = (uuid) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.wishCategories) {
        resolve([]);
      }
      const found = theProfile.wishCategories.findIndex((item) => {
        return (item.uuid === uuid);
      });
      if (found >= 0) {
        theProfile.wishCategories.splice(found, 1);
      }
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        resolve(updatedProfile.wishCategories);
        return;
      });
    });
  });
};

export const getDefaultWishCategories = () => {
  const SAY = new Locale([DICTIONARY]);
  return [
    {
      value: '',
      label: SAY.uncategorized,
    },
  ];
};

export const getWishCategoryByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    getActiveProfile().then((theProfile) => {
      if (uuid === 'new') {
        return resolve(WISH_CATEGORY);
      }
      if (!theProfile.wishCategories) {
        return reject(null);
      }
      const found = theProfile.wishCategories.findIndex((item) => {
        return (item.uuid === uuid);
      });
      return found >= 0 ? resolve(theProfile.wishCategories[found]) : reject();
    });
  });
};

export const getWishCategories = () => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      return resolve(theProfile.wishCategories || []);
    });
  });
};

export const updateWishCategory = (uuid, data) => {
  return new Promise((resolve) => {
    getActiveProfile().then((theProfile) => {
      if (!theProfile.wishCategories) {
        resolve([]);
      }
      const found = theProfile.wishCategories.findIndex((item) => {
        return (item.uuid === uuid);
      });
      if (found >= 0) {
        theProfile.wishCategories[found] = Object.assign(
          {},
          theProfile.wishCategories[found],
          data
        );
      }
      updateProfile(theProfile.uuid, theProfile).then((updatedProfile) => {
        resolve(updatedProfile.wishCategories);
        return;
      });
    });
  });
};
