import './Toolbar.scss';
import { useState } from 'react';
import Headroom from 'react-headroom';
import ToolbarButtonBack from './components/ToolbarButtonBack/ToolbarButtonBack';
import ToolbarButtonHome from './components/ToolbarButtonHome/ToolbarButtonHome';
import ToolbarButton from './components/ToolbarButton/ToolbarButton';
import ToolbarTitle from './components/ToolbarTitle/ToolbarTitle';
import ToolbarMenu from './components/ToolbarMenu/ToolbarMenu';
import Icon from '../Icon/Icon';

import Menu from '../../components/Menu/MainMenu';
import Sidebar from '../../components/Sidebar/Sidebar';

function Toolbar(props) {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  /**
   * Build toolbar buttons based on the `options` array.
   * @var object[] options
   */
  function makeOptionButtons(options) {
    if (!options || !options.length) {
      return null;
    }
    const r = options.map((option, i) => {
      return (
        <ToolbarButton className="customOption" key={`toolbarButton_${i}`} {...option}>
          <Icon name={option.icon} />
        </ToolbarButton>
      );
    });
    return r;
  }

  let backButton = null;
  if (props.goBack) {
    backButton = <ToolbarButtonBack />;
  } else if (props.backTo) {
    backButton = <ToolbarButtonBack to={props.backTo} />;
  } else if (props.backButton) {
    backButton = props.backButton;
  } else if (props.onClose) {
    backButton = (
      <ToolbarButton onClick={props.onClose}>
        <Icon name="close" />
      </ToolbarButton>
    );
  }
  const homeButton = props.goHome ? <ToolbarButtonHome /> : null;
  const title = props.title ? (
    <ToolbarTitle title={props.title} subTitle={props.subTitle} />
  ) : null;

  const options = makeOptionButtons(props.options || []);
  const avatarButton = () => {
    if (!props.enableMenu) {
      return null;
    } else {
      return <ToolbarMenu onClick={(evt) => onToolbarAvatarClick(evt)} />;
    }
  };

  function onToolbarAvatarClick() {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  function withMenu() {
    if (!props.withMenu) {
      return null;
    } else {
      return <Menu fixed />;
    }
  }

  function withSidebar() {
    if (!props.withSidebar) {
      return null;
    } else {
      return (
        <Sidebar
          withMainMenu
          isOpen={sidebarIsOpen}
          onClose={() => setSidebarIsOpen(false)}
        />
      );
    }
  }
  return (
    <>
      <Headroom>
        <div className="Toolbar">
          {backButton}
          {homeButton}
          {title}
          {options}
          {avatarButton()}
        </div>
      </Headroom>
      <div className="headerSpacer"></div>
      {withMenu()}
      {withSidebar()}
    </>
  );
}

export default Toolbar;
