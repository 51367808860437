export const getActiveLangauge = () => {
  return localStorage.getItem('I2GoProfileLanguage') || null;
};

export const getStoredAppVersion = () => {
  return localStorage.getItem('I2GoAppVersion') || null;
};

export const setActiveLanguage = (language) => {
  localStorage.setItem('I2GoProfileLanguage', language);
};

export const storeAppVersion = (value) => {
  localStorage.setItem('I2GoAppVersion', value);
};
