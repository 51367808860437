import { useState, useEffect } from 'react';
import { getAccountSettings } from '../storage/settings';

export const useAccountSettings = () => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getAccountSettings()
        .then((settings) => {
          setLoading(false);
          setData(settings);
        })
        .catch((error) => {
          setLoading(false);
          setStatus({
            ok: false,
            error,
          });
        });
    }

    run();
  }, []);

  return [data, loading, status];
};
