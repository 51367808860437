import Button from './Button';

function OutlineButton(props) {
  const theme = props.theme || 'primary';
  const className = props.className || `btn outline ${theme}`;
  return (
    <Button className={className} {...props}>
      {props.children}
    </Button>
  );
}

export default OutlineButton;
