import { useEffect } from 'react';
import InputText from '../../../../../../components/Form/InputText';
import InputTextArea from '../../../../../../components/Form/InputTextArea';
import InputTime from '../../../../../../components/Form/InputTime';
import InputDate from '../../../../../../components/Form/InputDate';
import FormColumn from '../../../../../../components/Form/FormColumn';

function InvitationFormEventInput(props) {
  const { say, formik } = props;
  const isEvent = formik.values.service === 'event';

  useEffect(() => {
    if (props.onChange) {
      props.onChange(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <div className="InvitationFormEventInput fields">
      <FormColumn className="title">
        <InputText
          autoComplete="off"
          id="title"
          aria-label={say.label.title}
          label={say.label.title}
          name="title"
          placeholder={say.placeholder.title}
          tipText={say.explain.title || null}
          type="text"
        />
      </FormColumn>
      <FormColumn className="date">
        <InputDate
          id="date"
          aria-label={say.label.date}
          label={say.label.date}
          name="date"
        />
      </FormColumn>
      {isEvent && (
        <FormColumn className="startTm">
          <InputTime
            id="start_tm"
            aria-label={say.label.startTime}
            label={say.label.startTime}
            name="start_tm"
          ></InputTime>
        </FormColumn>
      )}
      {isEvent && (
        <FormColumn className="endTm">
          <InputTime
            id="end_tm"
            aria-label={say.label.endTime}
            label={say.label.endTime}
            name="end_tm"
          />
        </FormColumn>
      )}
      {isEvent && (
        <FormColumn className="intro">
          <InputTextArea
            autoComplete="off"
            id="intro"
            aria-label={say.label.intro}
            label={say.label.intro}
            name="intro"
            placeholder={say.placeholder.intro}
            tipText={say.explain.intro || null}
            type="text"
          />
        </FormColumn>
      )}
      {isEvent && (
        <FormColumn className="location">
          <InputTextArea
            autoComplete="off"
            id="location"
            aria-label={say.label.location}
            label={say.label.location}
            name="location"
            placeholder={say.placeholder.location}
            tipText={say.explain.location || null}
            type="text"
          />
        </FormColumn>
      )}
      <FormColumn className="notes">
        <InputTextArea
          autoComplete="off"
          id="notes"
          aria-label={say.label.notes}
          label={say.label.notes}
          name="notes"
          placeholder={say.placeholder.notes}
          tipText={say.explain.notes || null}
          type="text"
        />
      </FormColumn>
      <FormColumn className="signature">
        <InputText
          autoComplete="off"
          id="signature"
          aria-label={say.label.signature}
          label={say.label.signature}
          name="signature"
          placeholder={say.placeholder.signature}
          tipText={say.explain.signature || null}
          type="text"
        />
      </FormColumn>
      {/* <FormColumn className="safeword">
        <InputText
          autoComplete="off"
          id="safeword"
          aria-label={say.label.safeword}
          label={say.label.safeword}
          name="safeword"
          placeholder={say.placeholder.safeword}
          tipText={say.explain.safeword || null}
          type="text"
        />
      </FormColumn> */}
    </div>
  );
}

export default InvitationFormEventInput;
