import './ToolbarTitle.scss';

function ToolbarTitle(props) {
  const title = () => {
    if (!props.subTitle) {
      return <h1>{props.title}</h1>;
    } else {
      return (
        <>
          <h1>{props.title}</h1>
          <h2>{props.subTitle}</h2>
        </>
      );
    }
  };

  return <div className="ToolbarTitle">{title()}</div>;
}

export default ToolbarTitle;
