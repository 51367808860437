import ToolbarButtonMenu from '../ToolbarButtonMenu/ToolbarButtonMenu';

function ToolbarMenu(props) {
  function onClick(_evt) {
    if (props.onClick) {
      props.onClick(_evt);
    }
  }

  return (
    <div className="ToolbarMenu">
      <ToolbarButtonMenu onClick={(evt) => onClick(evt)} />
    </div>
  );
}

export default ToolbarMenu;
