import React, { Suspense } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useNavigate } from 'react-router-dom';
import ROUTES, { makePath } from '../../services/routes';
import Locale from '../../services/locale';
import DICTIONARY from './dictionary.json';
import ButtonsBar from '../../components/ButtonsBar/ButtonsBar';
import IconButton from '../../components/Button/IconButton';
import Loading from '../../components/Loading/Loading';
import { useGetWishCategoriesList } from '../../services/data/hooks/wish-categories';
import WishCategoriesList from './components/WishCategoriesList/WishCategoriesList';

const OnBoard = React.lazy(() =>
  import('./components/WishCategoriesOnBoard/WishCategoriesOnBoard')
);

function WishCategoriesPage() {
  const SAY = new Locale([DICTIONARY]);
  const [wishCategories, loadingWishCategories] = useGetWishCategoriesList();
  const navigate = new useNavigate();

  function onCardEdit(data) {
    navigate(makePath(ROUTES.wishCategoryEditor, { uuid: data.uuid }));
  }

  function onCreateClick() {
    navigate(makePath(ROUTES.wishCategoryEditor, { uuid: 'new' }));
  }

  function onWishCategorySelect(theWishCategory) {
    console.log('onWishCategorySelect', theWishCategory);
  }

  function renderListHtml() {
    const loadingData = loadingWishCategories;
    if (loadingData) {
      return <Loading />;
    } else if (!wishCategories || !wishCategories.length) {
      return <Suspense fallback={<Loading />}><OnBoard /></Suspense>;
    } else {
      return (
        <WishCategoriesList
          data={wishCategories}
          onCardEdit={(card) => onCardEdit(card)}
          onWishCategorySelect={(theWishCategory) =>
            onWishCategorySelect(theWishCategory)
          }
        />
      );
    }
  }

  return (
    <div className="pageWithMenuLayout">
      <Toolbar
        backTo={ROUTES.home}
        title={SAY.wishCategories}
        enableMenu
        withMenu
        withSidebar
      />
      <div className="WishCategoriesPage page withButtonsBar">
        {renderListHtml()}
        <ButtonsBar
          fixed
          main={<IconButton large icon="add" onClick={onCreateClick} />}
        />
      </div>
    </div>
  );
}

export default WishCategoriesPage;
