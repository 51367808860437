import './ProfileForm.scss';
import { Formik } from 'formik';
import InputText from '../../../../components/Form/InputText';
import InputSelect from '../../../../components/Form/InputSelect';
import FormLine from '../../../../components/Form/FormLine';
import Explain from '../../../../components/OnBoard/Explain';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../../../services/dictionary/common-dictionary.json';
import Locale from '../../../../services/locale';
import LANGUAGE_OPTIONS from '../../../../services/locale/languages.json';
import ButtonsBar from '../../../../components/ButtonsBar/ButtonsBar';
import Button from '../../../../components/Button/Button';
import OutlineButton from '../../../../components/Button/OutlineButton';
import { useProfilesList } from '../../../../services/data/hooks/profiles';

function ProfileForm(props) {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [profiles, loadingProfiles] = useProfilesList();

  function deleteButton() {
    if (
      !props.forEdit ||
      loadingProfiles ||
      (profiles && profiles.length < 2)
    ) {
      return null;
    } else {
      return (
        <OutlineButton theme="danger" type="button" onClick={deleteProfile}>
          {SAY.delete}
        </OutlineButton>
      );
    }
  }

  function deleteProfile() {
    if (props.onDelete) {
      props.onDelete(props.data);
    }
  }

  function onAvatarClick() {
    if (props.onAvatarClick) {
      props.onAvatarClick();
    }
  }

  function onSubmit(values, actions) {
    actions.setSubmitting(false);

    if (props.onSubmit) {
      props.onSubmit(values);
    }
  }

  return (
    <div className="ProfileForm">
      <Formik initialValues={props.data} onSubmit={onSubmit}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="avatar">
              <ProfileAvatar
                forEdit={props.forEdit || false}
                name={formik.values.avatar}
                onEditClick={onAvatarClick}
              />
              {!props.forEdit && <Explain>{SAY.explain.avatar}</Explain>}
            </div>
            <div className="fields">
              <FormLine>
                <InputText
                  autoComplete="off"
                  id="name"
                  label={SAY.label.name}
                  name="name"
                  tipText={SAY.explain.name}
                  type="text"
                />
              </FormLine>
              <FormLine>
                <InputSelect
                  id="language"
                  label={SAY.label.language}
                  name="language"
                  options={LANGUAGE_OPTIONS}
                  tipText={SAY.explain.language}
                />
              </FormLine>
            </div>
            <ButtonsBar
              fixed
              left={deleteButton()}
              right={<Button type="submit">{SAY.save}</Button>}
            ></ButtonsBar>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ProfileForm;
