import { getAppliedLanguage } from '../../locale';
import { useState, useEffect } from 'react';
import { SERVICES } from '../helpers/services';

export const useGetLocalizedServicesList = (lang) => {
  if (!lang) {
    lang = getAppliedLanguage();
  }
  const [data, setData] = useState(null);
  const [status] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      const localizedList = SERVICES.map((item) => {
        return {
          value: item.value,
          label: item.label[lang],
        };
      });
      setLoading(false);
      setData(localizedList);
    }

    run();
  }, [lang]);

  return [data, loading, status];
};
