import ZONES from './zones.json';

export const getDefaultZoneCode = () => {
  return ZONES[0].code;
};

export const getZonesForInputSelect = (language) => {
  return ZONES.map((item) => {
    return { value: item.code, label: item[`name_${language}`] };
  });
};


export const getZoneByCountryAndCode = (countryId, code) => {
  const found = ZONES.find(item => {
    return item.code === code && item.country_id === countryId;
  });
  return found;
};
