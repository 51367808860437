import './Icon.scss';
import sprite from '../../components/Icon/icon-sprite.svg';

function Icon(props) {
  const spinClass = props.spin ? ' icon-spin' : '';
  const clickableClass = props.clickable ? ' clickable' : '';
  const className = `i2goi${spinClass}${clickableClass}`;
  const shape = (
    <svg
      viewBox="0 0 24 24"
      className={className}
      fill={props.fill || null}
      onClick={onClick}
    >
      <use xlinkHref={sprite + `#i2goi-${props.name}`}></use>
    </svg>
  );

  function onClick(evt) {
    if (props.onClick && props.clickable) {
      props.onClick(evt);
    }
  }

  return shape;
}

export default Icon;
