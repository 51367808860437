import './InvitationOutbox.scss';
import { PropTypes } from 'prop-types';
import InvitationOutboxCard from './InvitationOutboxCard';
import InvitationOutboxOnBoard from './InvitationOutboxOnBoard';
import { invitationUrl } from '../../../../services/url';

function InvitationOutbox(props) {
  function removeFromList(entryUuid) {
    if (props.onRemoveFromList) {
      props.onRemoveFromList(entryUuid);
    }
  }

  function renderEntries() {
    if (!props.data.outbox || props.data.outbox.length < 1) {
      return null;
    }
    const sortedList = props.data.outbox.sort((a, b) => {
      if (a.name === b.name) return 0;
      return a.name < b.name ? -1 : 1;
    });

    return sortedList.map((entry, i) => {
      return (
        <InvitationOutboxCard
          entry={entry}
          index={i}
          invitationUuid={props.data.uuid}
          invitationPublished={props.data.published}
          key={entry.uuid}
          onRemoveClick={() => removeFromList(entry.uuid)}
          outboxUrl={invitationUrl(props.data.uuid, entry.uuid)}
          viewOnly={props.viewOnly}
        />
      );
    });
  }

  function render() {
    if (!props.data.outbox || props.data.outbox.length === 0) {
      return <InvitationOutboxOnBoard />;
    } else {
      return <div className="outboxList">{renderEntries()}</div>;
    }
  }

  return <div className="InvitationOutbox">{render()}</div>;
}

InvitationOutbox.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InvitationOutbox;
