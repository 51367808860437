import './Button.scss';
import { useState, useEffect, useRef } from 'react';
import Icon from '../Icon/Icon';
import AnimatedThumbUp from '../Icon/AnimatedThumbUp';
import Loading from '../Loading/Loading';

function IconButton(props) {
  const borderless = props.noBorder ? ' borderless' : '';
  const theme = props.theme || 'primary';
  const extraClassName = props.className || '';
  const className = ` solid icon-button ${theme}${borderless} ${extraClassName}`;
  const icon =
    typeof props.icon === 'string' ? <Icon name={props.icon} /> : props.icon;
  const [wasLoading, setWasLoading] = useState(false);
  const animatedElement = useRef(null);
  const animateWhenDone = props.animateWhenDone || false;

  let size = 'auto';

  if (props.size) {
    size = props.size;
  } else if (props.large) {
    size = 64;
  }

  const style = {
    width: `${size}px`,
    height: `${size}px`,
  };

  useEffect(() => {
    if (animateWhenDone) {
      if (wasLoading) {
        if (props.loading === false) {
          setWasLoading(false);
          runLoadingEndAnimation();
        }
      } else if (props.loading) {
        setWasLoading(true);
      }
    }
    // eslint-disable-next-line
  }, [props.loading]);

  function runLoadingEndAnimation() {
    if (animateWhenDone) {
      animatedElement.current.animate();
    }
  }

  function renderAnimatedElementHtml() {
    if (animateWhenDone) {
      return <AnimatedThumbUp size={size} ref={animatedElement} />;
    } else {
      return null;
    }
  }

  function renderHtml() {
    if (props.loading) {
      return <Loading inline small size={size || null} />;
    } else {
      return (
        <>
          <button
            className={className}
            name={props.name || 'button'}
            onClick={props.onClick}
            value={props.value || ''}
            style={style}
            type={props.type || 'button'}
          >
            {icon}
            {props.children}
            {renderAnimatedElementHtml()}
          </button>
        </>
      );
    }
  }

  return renderHtml();
}

export default IconButton;
