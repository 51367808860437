import './SettingsForm.scss';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../../../services/dictionary/common-dictionary.json';
import LANGUAGE_OPTIONS from '../../../../services/locale/languages.json';
import Locale from '../../../../services/locale';
import ButtonsBar from '../../../../components/ButtonsBar/ButtonsBar';
import Button from '../../../../components/Button/Button';
import FormLine from '../../../../components/Form/FormLine';
import InputText from '../../../../components/Form/InputText';
import InputSelect from '../../../../components/Form/InputSelect';
import Explain from '../../../../components/OnBoard/Explain';
import {
  getDefaultCountryCode,
  getCountriesForInputSelect,
} from '../../../../services/data/helpers/countries';
import {
  getDefaultZoneCode,
  getZonesForInputSelect,
} from '../../../../services/data/helpers/zones';

function SettingsForm(props) {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [draft] = useState(props.data);
  const [selectedCountry, setSelectedCountry] = useState(
    getDefaultCountryCode()
  );

  useEffect(() => {
    setSelectedCountry(draft.country);
  }, [draft]);

  function isCanadaSelected() {
    return selectedCountry === 'CA';
  }

  function onCountryChange(evt, formik) {
    const value = evt.target.value;
    const empty = (value) => {
      return value.length < 1;
    };
    setSelectedCountry(value);
    const selectedZone = formik.values.zone;
    const zone = !empty(selectedZone) ? selectedZone : getDefaultZoneCode();
    formik.setValues(
      Object.assign({}, formik.values, { country: value, zone })
    );
  }

  function onSubmit(values, actions) {
    actions.setSubmitting(true);
    if (props.onSubmit) {
      props.onSubmit(values);
    }
    actions.setSubmitting(false);
  }

  return (
    <div className="SettingsForm">
      <Formik initialValues={draft} onSubmit={onSubmit}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <fieldset>
              <FormLine>
                <InputText
                  id="emailAddress"
                  label={SAY.emailAddress.label}
                  name="emailAddress"
                />
              </FormLine>
              <FormLine>
                <Explain className="medium" asHtml>
                  {SAY.emailAddress.description}
                </Explain>
              </FormLine>
            </fieldset>

            <fieldset>
              <FormLine>
                <InputSelect
                  id="language"
                  label={SAY.language.label}
                  name="language"
                  options={LANGUAGE_OPTIONS}
                />
              </FormLine>
              <FormLine>
                <Explain className="medium" asHtml>
                  {SAY.language.description}
                </Explain>
              </FormLine>
            </fieldset>

            <fieldset>
              <h2>{SAY.billing.title}</h2>
              <FormLine>
                <InputSelect
                  id="country"
                  label={SAY.billing.country.label}
                  name="country"
                  onChange={(evt) => onCountryChange(evt, formik)}
                  options={getCountriesForInputSelect(SAY.getLanguage())}
                />
              </FormLine>
              {isCanadaSelected() && (
                <FormLine>
                  <InputSelect
                    id="zone"
                    label={SAY.billing.zone.label}
                    name="zone"
                    options={getZonesForInputSelect(SAY.getLanguage())}
                  />
                </FormLine>
              )}
              <FormLine>
                <Explain className="medium" asHtml>
                  {SAY.billing.description}
                </Explain>
              </FormLine>
            </fieldset>

            <ButtonsBar
              fixed
              right={<Button type="submit">{SAY.save}</Button>}
            ></ButtonsBar>
          </form>
        )}
      </Formik>
    </div>
  );
}

SettingsForm.propTypes = {
  data: PropTypes.object,
};

export default SettingsForm;
