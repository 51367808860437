import { getActiveProfile } from '../data/helpers/profiles';

const ROUTES = {
  home: '/',
  checkout: '/checkout/:uuid',
  profiles: '/profiles',
  profileEditor: '/profiles/:uuid',
  themes: '/themes',
  invitations: '/invitations',
  invitationGuests: '/invitations/:uuid/guests',
  invitationEditor: '/invitations/:uuid',
  invitationOutbox: '/invitations/:uuid/outbox',
  invitationOutboxView: '/invitations/:uuid/outbox?viewOnly',
  invitationPosts: '/invitations/:uuid/posts',
  wishCategories: '/wish-categories',
  wishCategoryEditor: '/wish-categories/:uuid',
  wishItems: '/wishitems',
  wishItemEditor: '/wishitems/:uuid',
  settings: '/settings',
};
export default ROUTES;

const REDIRECTION_MAP = [
  function (sessionIsReady, location) {
    return new Promise((resolve) => {
      if (!isProfilesRoute(location.pathname) && sessionIsReady) {
        getActiveProfile().then((activeProfile) => {
          if (!activeProfile) {
            return resolve(ROUTES.profiles);
          }
        });
      }
    });
  },
  function (sessionIsReady, location) {
    return new Promise((resolve) => {
      if (!isProfilesRoute(location.pathname) && sessionIsReady) {
        getActiveProfile().then((activeProfile) => {
          if (!activeProfile) {
            resolve(ROUTES.profiles);
            return;
          }
          resolve(false);
        });
      }
    });
  },
];

export const calculateRediretionTarget = async (sessionIsReady, location) => {
  let redirectionTarget = false;
  for (let i = 0; i < REDIRECTION_MAP.length; i++) {
    const evalRedirectionCondition = REDIRECTION_MAP[i];
    redirectionTarget = await evalRedirectionCondition(
      sessionIsReady,
      location
    );
    if (redirectionTarget) {
      break;
    }
  }
  return redirectionTarget;
};

export const isProfilesRoute = (pathName) => {
  return pathName.substring(0, ROUTES.profiles.length) === ROUTES.profiles;
};

export const makePath = (route, params = {}) => {
  let parsedPath = route;
  Object.keys(params).forEach((key) => {
    const dkey = `:${key}`;
    parsedPath = parsedPath.replaceAll(dkey, params[key]);
  });
  return parsedPath;
};
