import './WishCategoriesList.scss';
import PropTypes from 'prop-types';
import Card from '../WishCategoryCard/WishCategoryCard';

function WishCategoriesList(props) {
  function onCardEdit(data) {
    if (props.onCardEdit) {
      props.onCardEdit(data);
    }
  }

  function renderListHtml() {
    return props.data.map((item, i) => {
      return <Card data={item} onEdit={onCardEdit} key={i} />;
    });
  }

  return (
    <div className="WishCategoriesList">
      <div className="listContainer">{renderListHtml()}</div>
    </div>
  );
}

WishCategoriesList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default WishCategoriesList;
