import './InvitationServiceSelector.scss';
import Locale from '../../../../../../services/locale';
import DICTIONARY from './dictionary.json';

function InvitationServiceSelector(props) {
  const SAY = new Locale([DICTIONARY]);

  function onSelect(service) {
    if (props.onSelect) {
      props.onSelect(service);
    }
  }

  return (
    <div className="InvitationServiceSelector" style={{ width: '100%' }}>
      <div className="option event" onClick={() => onSelect('event')}>
        <h2>{SAY.event.title}</h2>
        <p>{SAY.event.description}</p>
      </div>
      <div className="option wishlist" onClick={() => onSelect('wishlist')}>
        <h2>{SAY.wishList.title}</h2>
        <p>{SAY.wishList.description}</p>
      </div>
    </div>
  );
}

export default InvitationServiceSelector;
