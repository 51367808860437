import './InvitationsList.scss';
import PropTypes from 'prop-types';
import InvitationCard from '../../../../components/InvitationCard/InvitationCard';

function InvitationsList(props) {
  function onCheckout(data) {
    if (props.onCheckout) {
      props.onCheckout(data);
    }
  }

  function onCardEdit(data) {
    if (props.onCardEdit) {
      props.onCardEdit(data);
    }
  }

  function renderListHtml() {
    return props.data.map((invitationItem, i) => {
      const theme = props.themes.find((themeItem) => {
        return themeItem.uuid === invitationItem.theme_uuid;
      });
      return (
        <InvitationCard
          data={invitationItem}
          onCheckout={onCheckout}
          onEdit={onCardEdit}
          theme={theme}
          key={i}
        />
      );
    });
  }

  return (
    <div className="InvitationsList">
      <div className="listContainer">{renderListHtml()}</div>
    </div>
  );
}

InvitationsList.propTypes = {
  data: PropTypes.array.isRequired,
  onInvitationSelect: PropTypes.func.isRequired,
  themes: PropTypes.array.isRequired,
};

export default InvitationsList;
