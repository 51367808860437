import COUNTRIES from './countries.json';

export const getDefaultCountryCode = () => {
  return COUNTRIES[0].iso2;
};

export const getCountriesForInputSelect = (language) => {
  return COUNTRIES.map((item) => {
    return { value: item.iso2, label: item[`name_${language}`] };
  });
};

export const getCountryByIso2 = (iso2) => {
  const found = COUNTRIES.find((item) => {
    return item.iso2 === iso2;
  });
  const country = found || COUNTRIES[0];
  return country;
};
