import './InvitationEditorPage.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import COMMON_DICTIONARY from '../../services/dictionary/common-dictionary.json';
import DICTIONARY from './dictionary.json';
import Locale from '../../services/locale';
import Toolbar from '../../components/Toolbar/Toolbar';
import FormColumn from '../../components/Form/FormColumn';
import StatusBanner from '../../components/StatusBanner/StatusBanner';
import InvitationFormThemeInput from './components/InvitationFormThemeInput/InvitationFormThemeInput';
import InvitationForm from './components/InvitationForm/InvitationForm';
import { useGetInvitationByUuid } from '../../services/data/hooks/invitations';
import generateUuid from '../../services/data/helpers/uuid';
import { scrollToTop } from '../../services/dom';
import {
  addInvitation,
  deleteInvitationByUuid,
  publishInvitationByUuid,
  resetInvitationDraft,
  setInvitationDraft,
  unpublishInvitationByUuid,
  updateInvitation,
} from '../../services/data/helpers/invitations';
import ROUTES, { makePath } from '../../services/routes/';

import Loading from '../../components/Loading/Loading';
import NetworkError from '../../components/OnBoard/NetworkError';
import ThemesList from '../../components/ThemesList/ThemesList';
import { useGetThemes } from '../../services/data/hooks/themes';
import { toast } from 'react-toastify';

function InvitationEditorPage() {
  const [themes, ajaxLoadingThemes, ajaxErrorLoadingThemes] = useGetThemes();
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [view, setView] = useState('form');
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [mode] = useState(!uuid || uuid === 'new' ? 'create' : 'edit');
  const [theInvitation, loadingInvitation] = useGetInvitationByUuid(uuid);
  const [invitationIsUpdating, setInvitationIsUpdating] = useState(false);
  const [data, setData] = useState();
  const [selectedTheme, setSelectedTheme] = useState(null);

  useEffect(() => {
    if (!loadingInvitation && theInvitation) {
      setData(theInvitation);
      updateSelectedTheme(theInvitation.theme_uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theInvitation, themes]);

  function deleteInvitation(invitation) {
    deleteInvitationByUuid(invitation.uuid)
      .then(() => {
        toast.success(
          SAY.formatString(SAY.invitationDeleted, invitation.title),
          {
            autoClose: 1000,
          }
        );
        navigateBack();
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  function getInvitationOptions() {
    const r = [
      {
        icon: 'person-add',
        onClick: () => {
          onNavigateToOutbox();
        },
      },
    ];
    if (mode === 'edit' && data.published) {
      r.push({
        icon: 'quick-phrases',
        onClick: () => {
          onNavigateToPosts();
        },
      });
    }
    return r;
  }

  function navigateBack() {
    navigate(ROUTES.invitations);
  }

  function onFormCancel() {
    setInvitationDraft(null).then(() => {
      navigateBack();
    });
  }

  function onFormChange(formData) {
    const newData = Object.assign({}, data, formData);
    setData(newData);
    if (mode === 'create') {
      updateDraft(newData);
    }
  }

  function onNavigateToOutbox() {
    if (mode === 'edit') {
      navigate(
        makePath(ROUTES.invitationOutbox, {
          uuid: data.uuid,
          published: data.published || false,
        })
      );
    } else {
      toast.info(SAY.saveBeforeAddingContacts, {
        autoClose: false,
        closeOnClick: true,
      });
    }
  }

  function onNavigateToPosts() {
    if (mode === 'edit') {
      navigate(
        makePath(ROUTES.invitationPosts, {
          uuid: data.uuid,
          published: data.published || false,
        })
      );
    }
  }

  function onPublish() {
    setInvitationIsUpdating(true);
    publishInvitationByUuid(data.uuid).then((modifiedInvitation) => {
      setData(modifiedInvitation);
      setInvitationIsUpdating(false);
    });
  }

  function onSubmit(values) {
    // Publishing is handled outside the form so we remove the field here
    // to make sure it doesn't override its current value.
    delete values.published;

    if (mode === 'create') {
      const newInvitationUuid = generateUuid();
      values.uuid = newInvitationUuid;
      addInvitation(values)
        .then(() => {
          resetInvitationDraft().then(() => {
            navigate(
              makePath(ROUTES.invitationEditor, {
                uuid: newInvitationUuid,
              })
            );
            window.location.reload();
          });
        })
        .catch((error) => console.error('ERROR', error));
    } else {
      setInvitationIsUpdating(true);
      updateInvitation(uuid, values)
        .then((updatedInvitation) => {
          resetInvitationDraft().then(() => {
            setInvitationIsUpdating(false);
            setData(updatedInvitation);
          });
        })
        .catch((error) => console.error('ERROR', error));
    }
  }

  function onThemeSelect(theme) {
    scrollToTop();
    const newData = Object.assign({}, data, { theme_uuid: theme.uuid });
    setData(newData);
    updateSelectedTheme(theme.uuid);
    setView('form');
  }

  function onUnpublish() {
    setInvitationIsUpdating(true);
    unpublishInvitationByUuid(data.uuid).then((modifiedInvitation) => {
      setInvitationIsUpdating(false);
      setData(modifiedInvitation);
    });
  }

  function updateSelectedTheme(uuid) {
    if (themes) {
      const theme =
        themes.data.find((item) => {
          return item.uuid === uuid;
        }) || null;
      setSelectedTheme(theme);
    }
  }

  function updateDraft(data) {
    setInvitationDraft(data);
  }

  function renderView() {
    const pageTitle = SAY.invitations;
    // const pageSubTitle =
    //   uuid === 'new' ? SAY.createNewInvitation : SAY.editInvitation;
    const pageSubTitle =
      !data || !data.service
        ? uuid === 'new'
          ? SAY.createNewInvitation
          : SAY.editInvitation
        : SAY.services[data.service];
    if (view === 'form' && data) {
      const serviceClass = data && data.service ? data.service : '';
      const className = `InvitationEditorPage page withButtonsBar ${serviceClass}`;
      return (
        <div className="pageWithMenuLayout">
          <Toolbar
            backTo={ROUTES.invitations}
            title={pageTitle}
            subTitle={pageSubTitle}
            options={getInvitationOptions()}
            enableMenu
            withMenu
            withSidebar
          />
          <div className={className}>
            <StatusBanner
              theme="green"
              published={data.published === 1 || data.published === true}
            >
              {SAY.published}
            </StatusBanner>
            <InvitationForm
              data={data}
              updating={invitationIsUpdating}
              forEdit={mode === 'edit'}
              onCancel={() => onFormCancel()}
              onChange={(newValues) => onFormChange(newValues)}
              onDelete={(invitation) => deleteInvitation(invitation)}
              onSubmit={(values, actions) => onSubmit(values, actions)}
              onPublish={() => onPublish()}
              onUnpublish={() => onUnpublish()}
            >
              <FormColumn className="theme">
                <InvitationFormThemeInput
                  onClick={() => setView('themes')}
                  theme={selectedTheme}
                  loading={ajaxLoadingThemes()}
                />
              </FormColumn>
            </InvitationForm>
          </div>
        </div>
      );
    } else if (view === 'themes') {
      if (ajaxLoadingThemes()) {
        return <Loading />;
      } else if (ajaxErrorLoadingThemes()) {
        return <NetworkError />;
      } else {
        return (
          <>
            <Toolbar
              title={SAY.chooseAnAvatar}
              onClose={() => setView('form')}
            />
            <ThemesList
              data={themes.data}
              metadata={themes.metadata}
              onThemeSelect={(theme) => onThemeSelect(theme)}
              service={data.service}
            />
          </>
        );
      }
    }
  }

  return <div className="fullWidhtLayout">{renderView()}</div>;
}

export default InvitationEditorPage;
