import './InvitationForm.scss';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../../../services/dictionary/common-dictionary.json';
import Locale from '../../../../services/locale';
// import Explain from '../../../../components/OnBoard/Explain';
import InvitationFormEventInput from './components/InvitationFormEventInput/InvitationFormEventInput';
import InvitationServiceSelector from './components/InvitationServiceSelector/InvitationServiceSelector';
import ButtonsBar from '../../../../components/ButtonsBar/ButtonsBar';
import IconButton from '../../../../components/Button/IconButton';
import OutlineButton from '../../../../components/Button/OutlineButton';
import { canDelete as canDeleteInvitation } from '../../../../services/data/helpers/invitations';
// import { useGetInvitationDraft } from '../../../../services/data/hooks/invitations';
import PublishingOptions from '../PublishingOptions/PublishingOptions';

function InvitationForm(props) {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [draft, setDraft] = useState(props.data);
  // const [invitationDraft] = useGetInvitationDraft();
  const wipFalse = {saving: false};
  const [wip, setWip] = useState(wipFalse);
  const buttonSize = 42;

  useEffect(() => {
    setDraft(props.data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    if (!props.updating) {
      setWip(wipFalse);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updating]);

  function deleteDraft() {
    if (props.onCancel) {
      props.onCancel();
    }
  }

  function deleteInvitation() {
    if (props.onDelete) {
      props.onDelete(props.data);
    }
  }

  function onPublishClick() {
    if (props.onPublish) {
      props.onPublish();
    }
  }

  function onUnpublishClick() {
    if (props.onUnpublish) {
      props.onUnpublish();
    }
  }

  function renderLeftButtons() {
    if (props.forEdit && canDeleteInvitation(props.data)) {
      return (
        <>
          <IconButton
            theme="danger"
            size={buttonSize}
            icon="delete-forever"
            type="button"
            onClick={deleteInvitation}
          />
          {renderPublishingOptions()}
        </>
      );
    } else if (props.forEdit) {
      return renderPublishingOptions();
    } else if (draft && !props.forEdit) {
      return (
        <OutlineButton
          onClick={() => deleteDraft()}
          type="button"
          theme="primary"
          leadingIcon="mop"
        >
          {SAY.cleanDraft}
        </OutlineButton>
      );
    }
  }

  function renderPublishingOptions() {
    return (
      <PublishingOptions
        data={props.data}
        onPublishClick={() => onPublishClick()}
        onUnpublishClick={() => onUnpublishClick()}
        updating={props.updating}
      />
    );
  }

  function formHtml() {
    if (!draft || !serviceSelected(draft)) {
      return (
        <InvitationServiceSelector
          onSelect={(service) => onServiceSelect(service)}
        />
      );
    } else {
      return (
        <>
          {props.children}
          <Formik initialValues={draft} onSubmit={onSubmit}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <InvitationFormEventInput
                  formik={formik}
                  say={SAY}
                  onChange={(data) => onChange(data)}
                />
                <ButtonsBar
                fixed
                  left={renderLeftButtons()}
                  right={
                    <IconButton
                      animateWhenDone
                      loading={wip.saving}
                      icon="save"
                      title="save"
                      type="submit"
                      size={buttonSize}
                    />
                  }
                ></ButtonsBar>
              </form>
            )}
          </Formik>
        </>
      );
    }
  }

  function onChange(data) {
    if (props.onChange) {
      data = Object.assign({}, data, {safeword: ''});
      props.onChange(data);
    }
  }

  function onServiceSelect(service) {
    const newDraft = Object.assign({}, draft, {
      service,
    });
    setDraft(newDraft);
    props.data.service = service;
  }

  function onSubmit(values, actions) {
    setWip({saving: true});
    actions.setSubmitting(true);

    if (props.onSubmit) {
      props.onSubmit(values);
    }
    actions.setSubmitting(false);
  }

  function serviceSelected(draft) {
    return (
      !!draft.service &&
      (draft.service === 'event' || draft.service === 'wishlist')
    );
  }

  return <div className="InvitationForm">{formHtml()}</div>;
}

InvitationForm.propTypes = {
  data: PropTypes.object,
  updating: PropTypes.bool,
};
export default InvitationForm;
