import { readRepo, writeRepo } from './';


export const getGuestActions = () => {
  return readGuestActions();
};

export const getGuests = () => {
  return readGuests();
};

export const getGuestsForInvitation = (invitationUuid) => {
  return new Promise((resolve) => {
    getGuests().then((guests) => {
      return resolve(guests[invitationUuid] || null);
    });
  });
};

const readGuestActions = () => {
  return new Promise((resolve) => {
    readRepo('guestActions').then((guestActions) => {
      if (!guestActions) {
        writeGuestActions([]).then((newGuestActions) => {
          return resolve(newGuestActions);
        });
      } else {
        return resolve(guestActions);
      }
    });
  });
};

const readGuests = () => {
  return new Promise((resolve) => {
    readRepo('guests').then((guests) => {
      if (!guests) {
        writeGuests([]).then((newGuests) => {
          return resolve(newGuests);
        });
      } else {
        return resolve(guests);
      }
    });
  });
};

export const setGuestActions = (values) => {
  return writeGuestActions(values);
};

export const setGuests = (values) => {
  return writeGuests(values);
};

export const writeGuestActions = (values) => {
  return new Promise((resolve) => {
    writeRepo('guestActions', values).then(() => {
      return resolve(values);
    });
  });
};

export const writeGuests = (values) => {
  return new Promise((resolve) => {
    writeRepo('guests', values).then(() => {
      return resolve(values);
    });
  });
};
