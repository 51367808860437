import './WishCategoryForm.scss';
import { useState } from 'react';
import { Formik } from 'formik';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../../../services/dictionary/common-dictionary.json';
import Locale from '../../../../services/locale';
import ButtonsBar from '../../../../components/ButtonsBar/ButtonsBar';
import Button from '../../../../components/Button/Button';
import OutlineButton from '../../../../components/Button/OutlineButton';
import InputText from '../../../../components/Form/InputText';
import InputTextArea from '../../../../components/Form/InputTextArea';
import FormLine from '../../../../components/Form/FormLine';

function WishCategoryForm(props) {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const [draft] = useState(props.data);

  function canDelete() {
    // TO-DO: We can delete if the item doesn't have reservations.
    return props.canDelete;
  }

  function onDelete() {
    if (props.onDelete) {
      props.onDelete(props.data);
    }
  }

  function onSubmit(data) {
    if (props.onSubmit) {
      props.onSubmit(data);
    }
  }

  function renderDeleteButton() {
    if (
      canDelete() &&
      props.data &&
      props.data.uuid &&
      props.data.uuid.length > 0
    ) {
      return (
        <OutlineButton theme="danger" onClick={onDelete}>
          {SAY.delete}
        </OutlineButton>
      );
    }
  }

  return (
    <div className="WishCategoryForm fields">
      <Formik initialValues={draft} enableReinitialize onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="fields">
              <FormLine>
                <InputText label={SAY.label.name} name="name" />
              </FormLine>
              <FormLine>
                <InputTextArea
                  label={SAY.label.description}
                  name="description"
                />
              </FormLine>
            </div>
            <ButtonsBar
              fixed
              left={renderDeleteButton()}
              right={<Button type="submit">{SAY.save}</Button>}
              
            ></ButtonsBar>
            {!canDelete() && <p>{SAY.tipText.whyCantDelete}</p>}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default WishCategoryForm;
