import React, { Suspense } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useNavigate } from 'react-router-dom';
import ROUTES, { makePath } from '../../services/routes';
import Locale from '../../services/locale';
import DICTIONARY from './dictionary.json';
import ButtonsBar from '../../components/ButtonsBar/ButtonsBar';
import IconButton from '../../components/Button/IconButton';
import Loading from '../../components/Loading/Loading';
import { useGetWishItemsList } from '../../services/data/hooks/wish-items';
import WishItemsList from './components/WishItemsList/WishItemsList';

const OnBoard = React.lazy(() =>
  import('./components/WishListOnBoard/WishListPageOnBoard')
);

function WishListPage() {
  const SAY = new Locale([DICTIONARY]);
  const [wishItems, loadingWishItems] = useGetWishItemsList();
  const navigate = new useNavigate();

  function onCardEdit(data) {
    navigate(makePath(ROUTES.wishItemEditor, { uuid: data.uuid }));
  }

  function onCreateClick() {
    navigate(makePath(ROUTES.wishItemEditor, { uuid: 'new' }));
  }

  function onWishItemSelect(theWishItem) {
    console.log('onWishItemSelect', theWishItem);
  }

  function renderListHtml() {
    const loadingData = loadingWishItems;
    if (loadingData) {
      return <Loading />;
    } else if (!wishItems || !wishItems.length) {
      return <Suspense fallback={<Loading />}><OnBoard /></Suspense>;
    } else {
      return (
        <WishItemsList
          data={wishItems}
          onCardEdit={(card) => onCardEdit(card)}
          onWishItemSelect={(theWishItem) =>
            onWishItemSelect(theWishItem)
          }
        />
      );
    }
  }

  return (
    <div className="pageWithMenuLayout">
      <Toolbar
        backTo={ROUTES.home}
        title={SAY.wishItems}
        enableMenu
        withMenu
        withSidebar
      />
      <div className="WishListPage page withButtonsBar">
        {renderListHtml()}
        <ButtonsBar
          fixed
          main={<IconButton large icon="add" onClick={onCreateClick} />}
        />
      </div>
    </div>
  );
}

export default WishListPage;
