import './ThemesList.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ThemeCard from '../ThemeCard/ThemeCard';
import ThemeFilters from './components/ThemeFilters/ThemeFilters';
import NoThemesFound from './components/NoThemesFound/NoThemesFound';
import { extractFiltersFromThemes } from '../../services/data/helpers/themes';

function ThemesList(props) {
  const [filters, setFilters] = useState({
    service: props.service || 'all',
    category: props.category || 'all',
  });

  function filtersHtml() {
    const filterOptions = extractFiltersFromThemes(props.data);
    return (
      <ThemeFilters
        filters={filters}
        lockedService={props.service || null}
        onChange={onChange}
        filterOptions={filterOptions}
      />
    );
  }

  function matchesFilters(item) {
    let r = true;
    if (filters.service && filters.service !== 'all') {
      r = r && filters.service === item.service;
    }
    if (filters.category && filters.category !== 'all') {
      const i = item.types.indexOf(filters.category);
      r = r && i >= 0;
    }
    return r;
  }

  function onChange(group, value) {
    const newValues = {
      [group]: value,
    };
    // WARNING HARDCODED!
    // Here we want to make sure that whenever we change the service
    // the selected category is mong the available values for that service.
    if (group === 'service') {
      newValues['category'] = 'all';
    }
    const newFilters = Object.assign({}, filters, newValues);
    setFilters(newFilters);
  }

  function onThemeSelect(theme) {
    if (props.onThemeSelect) {
      props.onThemeSelect(theme);
    }
  }

  function themeCardsHtml() {
    const matches = props.data.filter((item) => {
      return matchesFilters(item);
    });
    if (!matches || !matches.length) {
      return <NoThemesFound />;
    } else {
      return matches.map((item, i) => {
        return (
          <ThemeCard
            key={i}
            data={item}
            eventTypes={props.metadata.eventTypes}
            onThemeSelect={(theme) => onThemeSelect(theme)}
          />
        );
      });
    }
  }

  return (
    <div className="ThemesList">
      {filtersHtml()}
      <div className="listContainer">{themeCardsHtml()}</div>
    </div>
  );
}

ThemesList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ThemesList;
