import './AnimatedThumbUp.scss';
import { useImperativeHandle, useRef, forwardRef } from 'react';
import Icon from './Icon';

const AnimatedThumbUp = forwardRef((props, ref) => {
  const myRef = useRef(null);

  useImperativeHandle(ref, () => ({
    animate: (event) => {
      console.log('Animate!');
      myRef.current.classList.add('animate');
    },
  }));

  function renderHtml() {
    const animatedElementStyle = {
      width: props.size + 6,
      height: props.size + 6,
    };
    return (
      <div className="AnimateThumbUp" ref={myRef} style={animatedElementStyle}>
        <Icon name="custom-thumb-up" />
      </div>
    );
  }

  return renderHtml();
})

export default AnimatedThumbUp;
