import { getDeviceId } from '../storage/device-info';
import { getActiveProfile } from '../helpers/profiles';

export const apiDelete = ({ url, headers }) => {
  return apiRequest({ url, payload: null, headers, method: 'DELETE' });
};

export const apiGet = ({ url, headers }) => {
  return apiRequest({ url, payload: null, headers, method: 'GET' });
};

export const apiPost = ({ url, payload, headers }) => {
  return apiRequest({ url, payload, headers, method: 'POST' });
};

export const apiPut = ({ url, payload, headers }) => {
  return apiRequest({ url, payload, headers, method: 'PUT' });
};

const apiRequest = ({ url, payload, headers, method }) => {
  return new Promise((resolve, reject) => {
    const promises = [getDeviceId(), getActiveProfile()];
    Promise.all(promises).then((values) => {
      const deviceId = values[0];
      const profile = values[1];
      if (!deviceId || !profile) {
        return resolve(null);
      }
      headers = Object.assign(
        {},
        {
          'Content-Type': 'application/json',
          I2GoDeviceID: deviceId,
          I2GoProfileID: profile.uuid,
        },
        headers
      );
      const body = payload ? JSON.stringify(payload) : null;
      fetch(url, {
        body,
        headers,
        method,
      })
        .then((response) => {
          if (headers['Content-Type'] === 'application/json') {
            response.json().then((asJson) => {
              const r = {
                ok: response.ok,
                status: response.status,
                statusText: response.statusText,
                json: asJson,
              };
              return resolve(r);
            });
          } else {
            response.text().then((asHtml) => {
              const r = {
                ok: response.ok,
                status: response.status,
                statusText: response.statusText,
                html: asHtml,
              };
              return resolve(r);
            });
          }
        })
        .catch((err) => {
          return reject({
            code: -1,
            description: err,
          });
        });
    });
  });
};

/*
export const enqueueApiRequest = ({ url, method, payload, headers }) => {
  const queue = getActiveStateValue(STORE_NAME) || EMPTY_QUEUE;
  const body = payload ? JSON.stringify(payload) : null;
  const finalHeaders = Object.assign({}, headers || {}, {
    'Content-Type': 'application/json',
  });

  queue.items.push(
    Object.assign(
      {},
      { url, method, body },
      {
        attempts: 0,
        requiresAuthentication: requiresAuthentication(url),
        runAt: 0, // 0 = runs right away on the next cycle
        headers: finalHeaders,
      }
    )
  );
  setActiveStateValue(STORE_NAME, queue);
  processQueue();
};

const processQueue = () => {
  const queue = getActiveStateValue(STORE_NAME) || EMPTY_QUEUE;
  if (queue.running) {
    return;
  }
  queue.running = true;
  setActiveStateValue(STORE_NAME, queue);

  if (queue.items.length < 1) {
    return;
  }

  const items = [...queue.items];
  for (let i = 0; i < items.length; i++) {
    // TO-DO fetch(...)
    console.log('RUN API REQUEST', items[i]);

    sendRequest(items[i])
      .then(() => {
        items[i].status = 'ok';
      })
      .catch(() => {
        items[i].status = 'failed';
      });

    items[i] = Object.assign({}, items[i], {
      attempts: items[i].attempts + 1,
      runAt: new Date().getTime(),
    });
    setActiveStateValue(STORE_NAME, queue);
  }
};

const requiresAuthentication = (url) => {
  // Does the URL refers to a private endpoint?
  return false;
};
*/
