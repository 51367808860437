import { useState, useEffect } from 'react';
import { makeApiUrl } from './';
import { apiGet } from './ajax';

export const useAjaxGet = (endpoint, fields = []) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      apiGet({
        url: makeApiUrl(endpoint, fields),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          setLoading(false);
          if (!response.ok) {
            setError({
              code: response.status,
              description: response.statusText,
            });
          } else {
            setData(response.json);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError({
            code: -1,
            description: err,
          });
        });
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ajaxLoading = () => {
    return loading || (!data && !ajaxError());
  };

  const ajaxError = () => {
    return !loading && error !== null;
  };

  return [data, ajaxLoading, ajaxError];
};
