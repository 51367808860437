class Engine {
  debug = false;
  running = true;
  subscribers = [];
  interval = 10000; // 10 seconds
  timer = null;

  exists(callback) {
    const found = this.subscribers.find((subscriber) => {
      return subscriber === callback;
    });
    return !!found;
  }

  run() {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log('BE.run', 'subscribers', this.subscribers.length);
    }
    for (let subscriber of this.subscribers) {
      if (typeof subscriber === 'function') {
        if (this.debug) {
          console.log('BE.run subscriber:', subscriber);
        }
        subscriber.call();
      }
    }
    if (this.running) {
      this.start();
    }
    return this;
  }

  setDebug(v) {
    this.debug = !!v;
    return this;
  }

  start() {
    this.running = true;
    const run = this.run.bind(this);
    this.timer = setTimeout(run, this.interval);
    // eslint-disable-next-line no-console
    if (this.debug) {
      console.info('BE.start');
    }
    return this;
  }

  stop() {
    clearTimeout(this.timer);
    this.running = false;
    // eslint-disable-next-line no-console
    if (this.debug) {
      console.info('BE.stop');
    }
    return this;
  }

  subscribe(callback, checkDups = false) {
    if (!checkDups || !this.exists(callback)) {
      this.subscribers.push(callback);
      if (this.debug) {
        // eslint-disable-next-line no-console
        console.info('BE.subscribe', checkDups);
        console.log('BE.subscribers', this.subscribers.length);
      }
    }
    return this;
  }

  timeout() {
    clearTimeout(this.timer);
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.info('BE.timeout->run');
    }
    this.run();
  }

  unsubscribe(callback) {
    this.subscribers = this.subscribers.filter((item) => {
      return item !== callback ? item : null;
    });
  }
}

export const engineTimeout = () => {
  window.I2GO.engine.timeout();
};

export const initEngine = (defaultI2Go = {}) => {
  window.I2GO = window.I2GO || defaultI2Go;
  window.I2GO.engine = window.I2GO.engine || new Engine();
  window.I2GO.engine.run();
};

export default initEngine;
