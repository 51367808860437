const getEnv = (key) => {
  return process.env[`REACT_APP_${key}`];
};

export const envVarMatches = (key, withValue) => {
  const envValue = getEnv(key);
  return envValue === withValue;
}

export default getEnv;
