import Avatar from '../../../Avatar/Avatar';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import { useActiveProfile } from '../../../../services/data/hooks/profiles';


function ToolbarButtonMenu(props) {
  const [activeProfile, loadingActiveProfile] = useActiveProfile();

  function avatar() {
    if (!loadingActiveProfile && activeProfile) {
      return <Avatar name={activeProfile.avatar} />;
    }
  }

  function onClick(_evt) {
    if (props.onClick) {
      props.onClick(_evt);
    }
  }

  return (
    <div className="ToolbarButtonMenu">
      <ToolbarButton className="toolbarAvatar" onClick={(evt) => onClick(evt)}>
        {avatar()}
      </ToolbarButton>
    </div>
  );
}

export default ToolbarButtonMenu;
