import './TipText.scss';
import Explain from './Explain';
import mdToHtml from '../../services/markdown';

function TipText(props) {
  const html = mdToHtml(props.children);
  return (
    <Explain className="TipText">
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Explain>
  );
}

export default TipText;
