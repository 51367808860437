import './SettingsPage.scss';
import Locale from '../../services/locale';
import DICTIONARY from './dictionary.json';
import Toolbar from '../../components/Toolbar/Toolbar';
import ROUTES from '../../services/routes';
import SettingsForm from './components/SettingsForm/SettingsForm';
import { toast } from 'react-toastify';
import { useAccountSettings } from '../../services/data/hooks/settings';
import { setSettings } from '../../services/data/storage/settings';

function SettingsPage() {
  const SAY = new Locale(DICTIONARY);
  const [settings, loadingSettings] = useAccountSettings();

  function onSubmit(values) {
    setSettings(values)
      .then(() => {
        toast.success(SAY.message.saved, {
          autoClose: 1000,
        });
      })
      .catch((error) => console.error('ERROR', error));
  }

  function renderForm() {
    if (loadingSettings || !settings) {
      return null;
    } else {
      return <SettingsForm data={settings} onSubmit={onSubmit} />;
    }
  }

  return (
    <div className="pageWithMenuLayout">
      <Toolbar
        backTo={ROUTES.home}
        title={SAY.title}
        enableMenu
        withMenu
        withSidebar
      />
      <div className="SettingsPage page">{renderForm()}</div>
    </div>
  );
}

export default SettingsPage;
