import './ThemeFilters.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ThemeRadioFilter from '../ThemeRadioFilter/ThemeRadioFilter';
import IconButton from '../../../Button/IconButton';
import ThemeFiltersModalControls from './components/ThemeFiltersModalControls/ThemeFiltersModalControls';
import { filterName } from '../../../../services/data/helpers/theme-filters';
import { useGetLocalizedServicesList } from '../../../../services/data/hooks/services';
import { useGetLocalizedCategoriesList } from '../../../../services/data/hooks/event-types';
import { filterCategoriesListFromFilterOptions } from '../../../../services/data/helpers/themes';

function ThemeFilters(props) {
  const [modalFiltersOpen, setModalFiltersOpen] = useState(false);
  const [servicesList, servicesLoading] = useGetLocalizedServicesList();
  const [categoriesList, categoriesLoading] = useGetLocalizedCategoriesList();

  function hasCategories() {
    return categoriesList && !categoriesLoading;
  }

  function hasServices() {
    return servicesList && !servicesLoading;
  }

  function onChange(group, value) {
    if (props.onChange) {
      props.onChange(group, value);
    }
  }

  return (
    <div className="ThemeFilters">
      <div className="smallScreen">
        <div className="filterPopupToggler">
          <IconButton
            icon="filter"
            theme="secondary"
            size="32"
            onClick={() => setModalFiltersOpen(true)}
          />
          {hasServices() && hasCategories() && (
            <ThemeFiltersModalControls
              filters={props.filters}
              isOpen={modalFiltersOpen}
              lockedService={props.lockedService}
              onClose={() => setModalFiltersOpen(false)}
              onChange={onChange}
              services={servicesList}
              categories={filterCategoriesListFromFilterOptions(
                categoriesList,
                props.filterOptions.categories,
                props.filters.service
              )}
            />
          )}
        </div>
        {hasServices() && (
          <div className="appliedFilter">
            {filterName(servicesList, props.filters.service)}
          </div>
        )}
        {hasCategories() && (
          <div className="appliedFilter">
            {filterName(categoriesList, props.filters.category)}
          </div>
        )}
      </div>
      <div className="largeScreen">
        {!props.lockedService && hasServices() && (
          <ThemeRadioFilter
            name="service"
            onChange={onChange}
            options={servicesList}
            value={props.filters.service || 'all'}
          />
        )}
        {hasCategories() && (
          <ThemeRadioFilter
            name="category"
            onChange={onChange}
            options={filterCategoriesListFromFilterOptions(
              categoriesList,
              props.filterOptions.categories,
              props.filters.service
            )}
            value={props.filters.category || 'all'}
          />
        )}
      </div>
    </div>
  );
}

ThemeFilters.propTypes = {
  filterOptions: PropTypes.shape({
    categories: PropTypes.object.isRequired,
    tags: PropTypes.object.isRequired,
  }).isRequired,
  filters: PropTypes.shape({
    category: PropTypes.string,
    service: PropTypes.string,
  }),
  lockedService: PropTypes.string,
};

export default ThemeFilters;
