import React, { useEffect, Suspense } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import ButtonsBar from '../../components/ButtonsBar/ButtonsBar';
import DICTIONARY from './dictionary.json';
import Locale from '../../services/locale';
import { setActiveProfile } from '../../services/data/helpers/profiles';
import { useNavigate } from 'react-router-dom';
import ROUTES, { makePath } from '../../services/routes';
import IconButton from '../../components/Button/IconButton';
import Loading from '../../components/Loading/Loading';
import ProfilesList from './components/ProfilesList/ProfilesList';
import { useProfilesList } from '../../services/data/hooks/profiles';

const OnBoard = React.lazy(() =>
  import('./components/ProfilesOnBoard/ProfilesPageOnBoard')
);

function ProfilesPage() {
  const SAY = new Locale(DICTIONARY);
  const [profiles, loadingProfiles] = useProfilesList();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.remove('sidebarOpen');
  });

  function onCreateClick() {
    navigate(makePath(ROUTES.profileEditor, { uuid: 'new' }));
  }

  function onProfileSelect(theProfile) {
    setActiveProfile(theProfile).then(() => {
      navigate(ROUTES.home);
    });
  }

  function getContent() {
    if (loadingProfiles || !profiles || !profiles.length) {
      return (
        <Suspense fallback={<Loading />}>
          <OnBoard />
        </Suspense>
      );
    } else {
      return (
        <ProfilesList
          data={profiles}
          onProfileSelect={(theProfile) => onProfileSelect(theProfile)}
        />
      );
    }
  }

  function getSubTitle() {
    if (!profiles || !profiles.length) {
      return null;
    } else {
      return SAY.chooseAProfile;
    }
  }

  return (
    <div className="fullWidthContent">
      <Toolbar title={SAY.profiles} subTitle={getSubTitle()} />
      <div className="ProfilesPage page withButtonsBar">
        {getContent()}
        <ButtonsBar
          fixed
          main={<IconButton large icon="add" onClick={onCreateClick} />}
        />
      </div>
    </div>
  );
}

export default ProfilesPage;
