import './InvitationOutboxPage.scss';
import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Locale from '../../services/locale';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../services/dictionary/common-dictionary.json';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useGetInvitationByUuid } from '../../services/data/hooks/invitations';
import Loading from '../../components/Loading/Loading';
import InvitationOutbox from './components/InvitationOutbox/InvitationOutbox';
import Explain from '../../components/OnBoard/Explain';
import InputText from '../../components/Form/InputText';
import { Formik } from 'formik';
import FormLine from '../../components/Form/FormLine';
import FormColumn from '../../components/Form/FormColumn';
import Button from '../../components/Button/Button';
import {
  updateInvitation,
  getInvitationByUuid,
  hydratedOutboxEntry
} from '../../services/data/helpers/invitations';

function InvitationOutboxPage() {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const { uuid } = useParams();
  const [theInvitation, loadingInvitation] = useGetInvitationByUuid(uuid);
  const [data, setData] = useState();
  const [canSubmit, setCanSubmit] = useState(false);
  const [draft, setDraft] = useState({ guestName: '' });
  const [searchParams] = useSearchParams();
  const viewOnly = searchParams.has('viewOnly') || false;

  useEffect(() => {
    if (!loadingInvitation && theInvitation) {
      setData(theInvitation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theInvitation]);

  useEffect(() => {
    if (canSubmit) {
      setCanSubmit(false);
      setDraft({ guestName: '' });
      updateInvitation(data.uuid, data).then(() => {
        getInvitationByUuid(data.uuid).then((result) => {
          setData(result);
        });
      });
    }
    // eslint-disable-next-line
  }, [data]);

  function removeFromList(entryUuid) {
    const outbox = data.outbox.filter((item) => {
      return item.uuid !== entryUuid;
    });
    setCanSubmit(true);
    setData(Object.assign({}, data, { outbox }));
  }

  function onSubmit({ guestName }, { resetForm }) {
    const outbox = data.outbox || [];
    outbox.push(hydratedOutboxEntry(guestName));
    setData(Object.assign({}, data, { outbox }));
    resetForm({});
  }

  function renderOutboxEntryForm() {
    return (
      <div className="outboxEntryForm">
        <Formik initialValues={draft} onSubmit={onSubmit}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <h3>{SAY.addNewEntry}</h3>
              <FormLine>
                <FormColumn>
                  <InputText
                    autoComplete="off"
                    formik={formik}
                    name="guestName"
                    onChange={(evt) => {
                      formik.handleChange(evt);
                      setCanSubmit(evt.target.value.length >= 3);
                    }}
                    label={SAY.entryName}
                  />
                </FormColumn>
              </FormLine>
              <FormLine>
                <FormColumn style={{ width: '100%', textAlign: 'right' }}>
                  <Button type="submit" disabled={!canSubmit}>
                    {SAY.add}
                  </Button>
                </FormColumn>
              </FormLine>
              <FormLine>
                <FormColumn>
                  <Explain asHtml>{SAY.explainOutbox}</Explain>
                </FormColumn>
              </FormLine>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  function render() {
    if (!data || loadingInvitation) {
      return <Loading />;
    } else {
      return (
        <>
          <Toolbar goBack title={SAY.invitationOutbox} />
          <div className="InvitationOutboxPage page">
            <h2>{data.title}</h2>
            <InvitationOutbox
              data={data}
              viewOnly={viewOnly}
              onRemoveFromList={(entryUuid) => removeFromList(entryUuid)}
            />
            {!viewOnly && renderOutboxEntryForm()}
          </div>
        </>
      );
    }
  }

  return <div className="fullWidhtLayout">{render()}</div>;
}

export default InvitationOutboxPage;
