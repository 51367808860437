import { useState, useEffect } from 'react';
import { getGuestActions, getGuests, getGuestsForInvitation } from '../storage/guests';

export const useGetGuestActions = () => {
  const [data, setData] = useState(null);
  const [status] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getGuestActions().then((actions) => {
        setLoading(false);
        setData(actions);
      });
    }

    run();
  }, []);

  return [data, loading, status];
};

export const useGetGuests = () => {
  const [data, setData] = useState(null);
  const [status] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getGuests().then((guests) => {
        setLoading(false);
        setData(guests);
      });
    }

    run();
  }, []);

  return [data, loading, status];
};

export const useGetGuestsForInvitation = (invitationUuid) => {
  const [data, setData] = useState(null);
  const [status] = useState({ ok: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function run() {
      setLoading(true);
      getGuestsForInvitation(invitationUuid).then((guests) => {
        setLoading(false);
        setData(guests);
      });
    }

    run();
  }, [invitationUuid]);

  return [data, loading, status];
};
