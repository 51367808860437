import './Button.scss';
import Icon from '../Icon/Icon';

function Button(props) {
  function onClick(_evt) {
    if (props.onClick) {
      props.onClick(_evt);
    }
  }

  const theme = props.theme || 'primary';
  const classSmall = props.small ? ' small' : '';
  const className = props.className || `btn solid ${theme}${classSmall}`;
  const icon = props.icon ? (
    <span className="icon">
      <Icon name={props.icon} />
    </span>
  ) : null;
  const leadingIcon = props.leadingIcon ? (
    <span className="leadingIcon">
      <Icon name={props.leadingIcon} />
    </span>
  ) : null;
  const trailingIcon = props.trailingIcon ? (
    <span className="trailingIcon">
      <Icon name={props.trailingIcon} />
    </span>
  ) : null;
  return (
    <button
      className={className}
      name={props.name || 'button'}
      value={props.value || ''}
      type={props.type || 'button'}
      onClick={(evt) => onClick(evt)}
      disabled={props.disabled || false}
      style={props.style || {}}
    >
      {leadingIcon}
      {icon}
      {props.children && <span>{props.children}</span>}
      {trailingIcon}
    </button>
  );
}

export default Button;
