import './ProfileCard.scss';
import Card from '../../../../components/Card/Card';
// import IconButton from '../../../../components/Button/IconButton';
import Avatar from '../../../../components/Avatar/Avatar';
import DICTIONARY from '../../../../services/dictionary/common-dictionary.json';
import Locale from '../../../../services/locale';
import IconButton from '../../../../components/Button/IconButton';
import ROUTES, { makePath } from '../../../../services/routes/';
import { useNavigate } from 'react-router-dom';

function ProfileCard(props) {
  const SAY = new Locale(DICTIONARY);
  const navigate = useNavigate();

  function onClick() {
    if (props.onClick) {
      props.onClick(props.data);
    }
  }

  function onEditClick(evt) {
    evt.stopPropagation();
    // Navigate to profiles/{props.data}
    navigate(makePath(ROUTES.profileEditor, { uuid: props.data.uuid }));
  }

  const name = props.data.name.length < 1 ? SAY.noName : props.data.name;
  return (
    <Card className="ProfileCard clickable" onClick={() => onClick()}>
      <Avatar name={props.data.avatar} />
      <div className="name">{name}</div>
      <div className="language">{SAY.languages[props.data.language]}</div>
      <div className="buttons">
        <IconButton
          icon="mode-edit"
          onClick={(evt) => onEditClick(evt)}
          theme="secondary"
        />
      </div>
    </Card>
  );
}

export default ProfileCard;
