import './StatusBanner.scss';
import { PropTypes } from 'prop-types';

function StatusBanner(props) {
  function renderHtml() {
    let className = 'StatusBanner ' + (props.theme || 'default');
    if (props.published) {
      return <div className={className}>{props.children}</div>;
    }
    return null;
  }

  return renderHtml();
}

StatusBanner.propTypes = {
  published: PropTypes.bool,
};

export default StatusBanner;
