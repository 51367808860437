import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/Icon/Icon';
import ToolbarButton from '../ToolbarButton/ToolbarButton';

function ToolbarButtonBack(props) {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  if (props.to) {
    return (
      <Link className="ToolbarButtonBack" to={props.to}>
        <ToolbarButton>
          <Icon name="arrow-back" />
        </ToolbarButton>
      </Link>
    );
  } else {
    const clickHandler = props.onClick ? props.onClick : goBack;
    return (
      <div className="ToolbarButtonBack" onClick={clickHandler}>
        <ToolbarButton>
          <Icon name="arrow-back" />
        </ToolbarButton>
      </div>
    );
  }
}

export default ToolbarButtonBack;
