function DefaultAvatar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      version="1.1"
      className="defaultAvatarSvg"
    >
      <defs id="defs4">
        <linearGradient
          xlinkHref="#linearGradient4223"
          id="DefaultAvatarSvglinearGradient4156"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1 0 0 -1.01295 0 -13.62581)"
          x1="48.92857"
          y1="-1017.1794"
          x2="48.92857"
          y2="-954.42072"
        />
        <linearGradient id="linearGradient4223">
          <stop offset="0" id="stop4225" stopColor="#59f" stopOpacity="1" />
          <stop offset="1" id="stop4227" stopColor="#447acc" stopOpacity="1" />
        </linearGradient>
      </defs>
      <g id="layer4" opacity=".999">
        <g
          transform="translate(0 -952.36217)"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeDasharray="none"
          strokeDashoffset="0"
          strokeOpacity="1"
        >
          <path
            opacity="1"
            fill="#000"
            strokeWidth=".2"
            d="M0 952.36218h100v100H0z"
          />
          <path
            d="M0 952.36214v2.59375c6.8968103 6.85693 17.945862 9.50783 26.894531 4.78906 1.788515 12.10849 11.429391 21.30624 23.13086 21.30664 11.643124-.0003 22.793356-9.21309 23.580078-21.1582 8.859877 4.36582 19.625776 1.67984 26.394531-5.05273v-2.47852z"
            opacity="1"
            fill="#fc0"
            strokeWidth="1"
          />
          <path
            d="M100 953.577c-6.76875 6.81974-17.534651 9.54149-26.394531 5.11914-.78672 12.09977-11.936948 21.43135-23.580078 21.43165-11.70147-.0004-21.34234-9.31677-23.13086-21.58204C17.945861 963.32562 6.89681 960.6399 0 953.69419v98.66801h100zm-72.466797 31.46289c5.02054.11652 9.771996 2.96637 12.072266 8.36915.09.27028-.116712.43672-.482422 0-6.39708-8.50942-17.677139-8.37587-25.105469 0-.17532.19917-.506408.28484-.361328 0 3.33101-5.75471 8.739663-8.48845 13.876953-8.36915zm48.552735 0c5.020539.11652 9.770042 2.96637 12.070312 8.36915.09.27028-.114739.43672-.480469 0-6.39708-8.50942-17.677119-8.37587-25.105469 0-.1753.19917-.508361.28484-.363281 0 3.33101-5.75471 8.741616-8.48845 13.878907-8.36915zm-3.234376 26.96291c.148751.025.200847.16.123047.3262-3.9745 6.6469-12.184675 10.1495-20.859375 10.2929-.0942 0-.188903-.0009-.283203 0-2.56124 7.4094 1.296742 15.5446 7.576172 20.9961.47394.4805.26804 1.2999-.375 1.4922l-1.673828.4981c-1.151562.3424-2.36347.6183-3.589844.832.000212 0-.000211 0 0 0-3.545307.6063-7.249606.7451-10.009765.1523-3.007625-.6459-5.971821-2.315-8.261719-4.6269-2.146535-2.1672-3.834815-5.0905-5.119141-8.3516a17.858232 16.638623 0 00-.002 0c-1.76263-4.5397-2.765676-10.2176-3.128906-17.3301l-.169922-3.3261c-.0247-.4976.356216-.9193.847656-.9395.16004-.01.310749.044.449219.1211.13131.036.273919.097.386719.1875 12.83498 10.3048 32.325776 10.4692 43.378906 0 .31595-.2686.562198-.3515.710937-.3262z"
            opacity="1"
            fill="url(#DefaultAvatarSvglinearGradient4156)"
            strokeWidth="1"
          />
          <path
            d="M30.373124 1033.6019c1.285085 3.2674 2.974964 6.1971 5.124923 8.3677 2.289898 2.3119 5.254094 3.981 8.261719 4.6269 2.760159.5928 6.464775.4534 10.010082-.1529-1.679306-10.0784-12.877587-16.1766-23.396724-12.8417z"
            opacity="1"
            fill="red"
            strokeWidth=".2"
          />
        </g>
      </g>
    </svg>
  );
}

export default DefaultAvatar;
