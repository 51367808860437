import OnBoard from '../../../../components/OnBoard/OnBoard';
import ONBOARD_MIDDLE_POINTER_IMAGE from '../../../../themes/img/onboard-middle-pointer.svg';
import mdToHtml from '../../../../services/markdown';
import DICTIONARY from '../../dictionary.json';
import Locale from '../../../../services/locale';

function InvitationOutboxOnBoard() {
  const SAY = new Locale(DICTIONARY);
  const html = mdToHtml(SAY.onBoard.invitationOutbox);
  return (
    <OnBoard>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <img
        src={ONBOARD_MIDDLE_POINTER_IMAGE}
        alt={SAY.onBoard.invitationOutbox}
      />
    </OnBoard>
  );
}

export default InvitationOutboxOnBoard;
