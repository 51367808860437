import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DICTIONARY from './dictionary.json';
import COMMON_DICTIONARY from '../../services/dictionary/common-dictionary.json';
import Locale from '../../services/locale';
import Toolbar from '../../components/Toolbar/Toolbar';
import ProfileForm from './components/ProfileForm/ProfileForm';
import AvatarSelectorGrid from './components/AvatarSelectorGrid/AvatarSelectorGrid';
import { useIdentifiedProfile } from '../../services/data/hooks/profiles';
import generateUuid from '../../services/data/helpers/uuid';
import {
  addProfile,
  deleteProfileByUuid,
  updateProfile,
} from '../../services/data/helpers/profiles';

import Loading from '../../components/Loading/Loading';
import NetworkError from '../../components/OnBoard/NetworkError';
import ROUTES from '../../services/routes/';
import { useGetAvatars } from '../../services/data/api/avatars';
import { toast } from 'react-toastify';

function ProfileEditorPage() {
  const SAY = new Locale([DICTIONARY, COMMON_DICTIONARY]);
  const { uuid } = useParams();
  const [mode] = useState(uuid === 'new' ? 'create' : 'edit');
  const [view, setView] = useState('form');
  const [theProfile, loadingProfile] = useIdentifiedProfile(uuid);
  const navigate = useNavigate();
  const [avatars, loadingAvatars, loadingAvatarsFailed] = useGetAvatars();

  const subTitle = uuid === 'new' ? SAY.createProfile : SAY.editProfile;

  function deleteProfile(theProfile) {
    deleteProfileByUuid(theProfile.uuid)
      .then(() => {
        toast.success(SAY.formatString(SAY.profileDeleted, theProfile.name), {
          autoClose: 1000,
        });
        navigateBack();
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  function navigateBack() {
    navigate(ROUTES.profiles);
  }

  function onAvatarClick() {
    setView('avatars');
  }

  function onAvatarSelect(uuid) {
    theProfile.avatar = uuid;
    setView('form');
  }

  function onAvatarSelectClose() {
    setView('form');
  }

  function onSubmit(values) {
    if (mode === 'create') {
      values.uuid = generateUuid();
      addProfile(values)
        .then(() => {
          navigateBack();
        })
        .catch((error) => console.error('ERROR', error));
    } else {
      updateProfile(uuid, values)
        .then(() => {
          navigateBack();
        })
        .catch((error) => console.error('ERROR', error));
    }
  }

  function renderView() {
    if (view === 'form' && !loadingProfile && theProfile) {
      return (
        <>
          <Toolbar goBack title={SAY.profiles} subTitle={subTitle} />
          <div className="ProfileEditorPage page withButtonsBar">
            <ProfileForm
              data={theProfile}
              forEdit={mode === 'edit'}
              onAvatarClick={() => onAvatarClick()}
              onDelete={deleteProfile}
              onSubmit={(values, actions) => onSubmit(values, actions)}
            />
          </div>
        </>
      );
    } else if (view === 'avatars') {
      if (loadingAvatars()) {
        return <Loading />;
      } else if (loadingAvatarsFailed()) {
        return <NetworkError />;
      } else {
        return (
          <>
            <Toolbar
              title={SAY.chooseAnAvatar}
              onClose={() => onAvatarSelectClose()}
            />
            <AvatarSelectorGrid
              avatars={avatars}
              onAvatarSelect={(uuid) => onAvatarSelect(uuid)}
            />
          </>
        );
      }
    }
  }

  return <div className="fullWidthContent">{renderView()}</div>;
}

export default ProfileEditorPage;
