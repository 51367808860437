import './ProfilesQuickAccess.scss';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../Avatar/Avatar';
import {
  useProfilesList,
  useActiveProfile,
} from '../../../../services/data/hooks/profiles';
import { setActiveProfile } from '../../../../services/data/helpers/profiles';
import DICTIONARY from './dictionary.json';
import Locale from '../../../../services/locale';
import ROUTES from '../../../../services/routes';

function ProfilesQuickAccess() {
  const SAY = new Locale(DICTIONARY);
  const [profilesList, loadingProfilesList] = useProfilesList();
  const [activeProfile, loadingActiveProfile] = useActiveProfile();
  const navigate = useNavigate();

  function activeProfileHtml() {
    if (loadingActiveProfile || !activeProfile) {
      return null;
    } else {
      return (
        <div
          className="profileQuickAccess activeProfile"
          onClick={() => navigate(ROUTES.profiles)}
        >
          <div className="profileName">
            {activeProfile.name} ({SAY.activeProfile})
          </div>
          <div className="profileAvatar">
            <div className="profileName">{SAY.activeProfile}</div>
            <Avatar name={activeProfile.avatar} />
          </div>
        </div>
      );
    }
  }

  function onAvatarClick(profile) {
    setActiveProfile(profile).then(() => {
      navigate(0);
    });
  }

  function profilesListHtml() {
    if (
      loadingProfilesList ||
      !profilesList ||
      loadingActiveProfile ||
      !activeProfile
    ) {
      return null;
    } else {
      return profilesList.map((aProfile) => {
        if (aProfile.uuid === activeProfile.uuid) {
          return false;
        }
        return (
          <div
            className="profileQuickAccess"
            key={aProfile.uuid}
            onClick={() => onAvatarClick(aProfile)}
          >
            <div className="profileName">{aProfile.name}</div>
            <div className="profileAvatar">
              <Avatar name={aProfile.avatar} />
            </div>
          </div>
        );
      });
    }
  }

  return (
    <div className="ProfilesQuickAccess">
      {activeProfileHtml()}
      {profilesListHtml()}
    </div>
  );
}

export default ProfilesQuickAccess;
